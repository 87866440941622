import {ConfirmationResult, RecaptchaVerifier} from 'firebase/auth'
import {atom} from 'recoil'
import {ApplicationDocType} from 'types'

export const drawerOpenState = atom<boolean>({
  key: 'drawerOpenState',
  default: false,
})

export const profileMenuState = atom<null | HTMLElement>({
  key: 'profileMenuState',
  default: null,
})

export const isApplyState = atom<boolean | HTMLElement>({
  key: 'isApplyState',
  default: false,
})

export const depositState = atom<boolean>({
  key: 'depositState',
  default: false,
})

export const depositOptionsState = atom<boolean>({
  key: 'depositOptionsState',
  default: false,
})

export const depositMessageState = atom<string>({
  key: 'depositMessageState',
  default: '',
})

export const depositAssetState = atom<string>({
  key: 'depositAssetState',
  default: '',
})

export const depositCustomTokenState = atom<`0x${string}` | undefined>({
  key: 'depositCustomTokenState',
  default: undefined,
})

export const applyStepState = atom<number>({
  key: 'applyStepState',
  default: 0,
})

export const stepState = atom({
  key: 'stepState',
  default: {currentStep: 0, steps: [false, false, false, false, false]}, // False means incomplete, true means complete
})

// Atom to manage the confirmation result for OTP verification
export const confirmationResultState = atom<ConfirmationResult | null>({
  key: 'confirmationResultState', // Unique key for the atom
  default: null, // Default state is null
})

// Atom to manage the phone verification status
export const isPhoneVerifiedState = atom<boolean>({
  key: 'isPhoneVerifiedState', // Unique key for the atom
  default: false, // Default state is false
})

export const applicationState = atom<ApplicationDocType | null>({
  key: 'applicationState', // Unique ID (with respect to other atoms/selectors)
  default: null, // Default value (aka initial value)
})

export const uploadedIDs = atom<string[] | undefined>({
  key: 'uploadedIDs',
  default: undefined,
})

export const profileIdState = atom<string | null>({
  key: 'profileIdState',
  default: null,
})

export const isOtcState = atom<boolean>({
  key: 'isOtcState',
  default: false,
})

export const profileStepState = atom<number>({
  key: 'profileStepState',
  default: 0,
})

export const recaptchaState = atom<RecaptchaVerifier | null>({
  key: 'recaptchaState',
  default: null,
})
