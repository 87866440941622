import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {Buffer} from 'buffer'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {WagmiProvider} from 'wagmi'
import App from './App.tsx'

import {CssBaseline, ThemeProvider} from '@mui/material'
import * as Sentry from '@sentry/react'
import {FirestoreComponents} from 'components'
import {ConnectKitProvider} from 'connectkit'
import {firebaseConfig} from 'data'
import Moralis from 'moralis'
import {BrowserRouter} from 'react-router-dom'
import {FirebaseAppProvider} from 'reactfire'
import {RecoilRoot} from 'recoil'
import {theme} from 'theme'
import './index.css'
import {config2} from './wagmiConfig.ts'

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://0cfe95103addaa170212ef327eee9c0c@o1241620.ingest.us.sentry.io/4507872252919808',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/pancak\.bio\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

globalThis.Buffer = Buffer

const queryClient = new QueryClient()

Moralis.start({
  apiKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImM4ZGE4YWIzLWUwOGUtNGJlNS04MTIwLTg4MWNmOGYzMmJmMSIsIm9yZ0lkIjoiNDA4MDgyIiwidXNlcklkIjoiNDE5MzI0IiwidHlwZUlkIjoiZjc1M2I0ZDgtMTZjZS00NjEzLWIyYzYtNDI5YjZiYmUwM2Q4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjYwODc0NzUsImV4cCI6NDg4MTg0NzQ3NX0.w8MMO5eSMVc9Tn3VJBH0296uljaWBBG9xWfawCCkKMM',
  // ...and any other configuration
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        {/* @ts-expect-error */}
        <WagmiProvider config={config2}>
          <QueryClientProvider client={queryClient}>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <FirestoreComponents>
                <ConnectKitProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </ConnectKitProvider>
              </FirestoreComponents>
            </FirebaseAppProvider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </WagmiProvider>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
)
