import {ArrowBack} from '@mui/icons-material'
import {Alert, Button, Stack, TextField, Typography} from '@mui/material'
import {PancakeMascot} from 'components'
import {FirebaseError} from 'firebase/app'
import {usePhoneVerification, useRecaptcha} from 'hooks'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useUser} from 'reactfire'

interface OTCFormValues {
  verifyPhoneNumber: string
}

export function OneTimeCode({
  onSuccess,
  onBack,
}: {
  onSuccess: () => void
  onBack: () => void
}) {
  const {
    verifyOtpCode,
    requestNewOtp,
    error: otpError,
    setError: setOtpError,
    phoneNumber,
    isPhoneVerified,
    hasOngoingVerification,
  } = usePhoneVerification()

  const {
    control,
    setValue,
    formState: {errors},
  } = useForm<OTCFormValues>({
    defaultValues: {
      verifyPhoneNumber: '',
    },
  })

  const [otp, setOtp] = useState<string[]>(Array(6).fill(''))
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])
  const {data: user} = useUser()
  const {resetRecaptcha} = useRecaptcha()

  const handleOtpSubmit = useCallback(
    async (otpCode: string) => {
      console.log('OTP Submitted:', otpCode)

      if (!otpCode || otpCode.length !== 6) {
        setOtpError('Please enter a valid 6-digit OTP.')
        return
      }

      if (!hasOngoingVerification) {
        setOtpError(
          'No ongoing verification. Please verify your phone number first.',
        )
        return
      }

      if (!user) {
        setOtpError('No user found. Please try again.')
        return
      }

      try {
        await verifyOtpCode(otpCode, user)
        onSuccess()
      } catch (error) {
        const e = error as FirebaseError
        console.log(error)
        if (e.code === 'auth/invalid-verification-code') {
          setOtpError(`That's not the secret code...`)
        } else if (e.code) {
          setOtpError(e.code)
        } else {
          setOtpError(`Some unresolved error, oopsie.`)
        }
      }
    },
    [hasOngoingVerification, setOtpError, verifyOtpCode, onSuccess, user],
  )

  const validateOTP = useCallback(
    (otpArray: string[]) => {
      const enteredOTP = otpArray.join('')
      if (enteredOTP.length === 6) {
        handleOtpSubmit(enteredOTP)
      }
    },
    [handleOtpSubmit],
  )

  const handleRequestNewOtp = useCallback(() => {
    console.log('Requesting new OTP...')
    resetRecaptcha()
    if (user && phoneNumber) {
      if (window.recaptchaVerifier === undefined)
        return setOtpError('reCAPTCHA not initialized. Please try again.')
      try {
        requestNewOtp(user, window.recaptchaVerifier)
      } catch (e) {
        console.error('Error sending new OTP:', e)
        setOtpError('Failed to send new OTP. Please try again.')
      }
    } else {
      let errorMessage = 'Unable to request new OTP. '
      if (!user) errorMessage += 'User not found. '
      if (!phoneNumber) errorMessage += 'Phone number not found. '
      if (!window.recaptchaVerifier)
        errorMessage += 'reCAPTCHA not initialized. '
      console.error(errorMessage)
      setOtpError(errorMessage + 'Please try again.')
    }
  }, [phoneNumber, user, requestNewOtp, setOtpError])

  const handleChange = useCallback(
    (index: number, value: string) => {
      if (isNaN(Number(value))) return

      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)
      setValue('verifyPhoneNumber', newOtp.join(''))

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus()
      }

      validateOTP(newOtp)
    },
    [otp, setValue, validateOTP],
  )

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Backspace') {
        e.preventDefault()
        if (otp[index] === '') {
          if (index > 0) {
            const newOtp = [...otp]
            newOtp[index - 1] = ''
            setOtp(newOtp)
            setValue('verifyPhoneNumber', newOtp.join(''))
            inputRefs.current[index - 1]?.focus()
            validateOTP(newOtp)
          }
        } else {
          const newOtp = [...otp]
          newOtp[index] = ''
          setOtp(newOtp)
          setValue('verifyPhoneNumber', newOtp.join(''))
          validateOTP(newOtp)
        }
      }
    },
    [otp, setValue, validateOTP],
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.preventDefault()
      const pastedData = event.clipboardData
        .getData('Text')
        .replace(/\D/g, '')
        .slice(0, 6)
      const newOtp = [...otp]
      pastedData.split('').forEach((char, index) => {
        if (index < 6) {
          newOtp[index] = char
        }
      })
      setOtp(newOtp)
      setValue('verifyPhoneNumber', newOtp.join(''))
      validateOTP(newOtp)

      const focusIndex = Math.min(pastedData.length, 5)
      setTimeout(() => {
        inputRefs.current[focusIndex]?.focus()
        inputRefs.current[focusIndex]?.setSelectionRange(1, 1)
      }, 0)
    },
    [otp, setValue, validateOTP],
  )

  useEffect(() => {
    if (!phoneNumber || !hasOngoingVerification) {
      onBack()
    }
  }, [phoneNumber, hasOngoingVerification, onBack])

  useEffect(() => {
    validateOTP(otp)
  }, [otp, validateOTP])

  const OtpInputs = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        onPaste={handlePaste}
      >
        {otp.map((digit, index) => (
          <Controller
            key={index}
            name="verifyPhoneNumber"
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                value={digit}
                onChange={e => handleChange(index, e.target.value)}
                inputRef={el => (inputRefs.current[index] = el)}
                variant="outlined"
                autoFocus={index === 0}
                onKeyDown={e => handleKeyDown(index, e)}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    inputProps: {maxLength: 1, pattern: '[0-9]*'},
                  },
                }}
                error={Boolean(errors.verifyPhoneNumber)}
                sx={{
                  width: '3rem',
                  textAlign: 'center',
                  input: {textAlign: 'center'},
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                  },
                }}
              />
            )}
          />
        ))}
      </Stack>
    ),
    [
      control,
      errors.verifyPhoneNumber,
      handleChange,
      handleKeyDown,
      handlePaste,
      otp,
    ],
  )

  return (
    <Stack spacing={2} alignItems="center">
      <PancakeMascot text="What's the secret code? 🤫" />
      {isPhoneVerified && (
        <>
          <Typography variant="body1" color="textSecondary">
            Enter the code sent to <strong>••••{phoneNumber?.slice(-4)}</strong>
          </Typography>
          {OtpInputs}
          {otpError && <Alert severity="error">{otpError}</Alert>}
          <Stack spacing={3} pt={1}>
            <Button
              color="primary"
              onClick={handleRequestNewOtp}
              fullWidth
              size="large"
            >
              Request New OTP
            </Button>
            <Button
              variant="contained"
              color="inherit"
              size="large"
              fullWidth
              onClick={onBack}
              startIcon={<ArrowBack />}
            >
              Change Number
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}
