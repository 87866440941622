import {Asset} from 'forms/donate/DonateModal'
import {CoinOption} from 'types'
import {encodeFunctionData, erc20Abi, parseUnits, PublicClient} from 'viem'

export const validateCustomToken = async (
  address: string,
  publicClient: PublicClient,
) => {
  if (!address.startsWith('0x') || address.length !== 42) {
    return false
  }

  try {
    const tokenContract = {
      address: address as `0x${string}`,
      abi: erc20Abi,
    }

    const [balance, decimals, symbol, name] = await Promise.all([
      publicClient
        .readContract({
          ...tokenContract,
          functionName: 'balanceOf',
          args: [address as `0x${string}`],
        })
        .catch(() => null),
      publicClient
        .readContract({
          ...tokenContract,
          functionName: 'decimals',
        })
        .catch(() => null),
      publicClient
        .readContract({
          ...tokenContract,
          functionName: 'symbol',
        })
        .catch(() => null),
      publicClient
        .readContract({
          ...tokenContract,
          functionName: 'name',
        })
        .catch(() => null),
    ])

    return (
      balance !== null && decimals !== null && symbol !== null && name !== null
    )
  } catch (error) {
    console.error('Error validating custom token:', error)
    return false
  }
}

export const prepareDonationTransaction = (
  donationCoin: Asset,
  amount: string,
  memo: string,
  recipientAddress: `0x${string}`,
) => {
  const amountInSmallestUnit = parseUnits(amount, donationCoin.decimals)

  if (donationCoin.token_address) {
    // ERC20 token transfer
    return {
      to: donationCoin.token_address,
      data: encodeFunctionData({
        abi: erc20Abi,
        functionName: 'transfer',
        args: [recipientAddress, amountInSmallestUnit],
      }),
      value: BigInt(0), // No ETH value for token transfers
    }
  } else {
    // Native token transfer
    return {
      to: recipientAddress,
      value: amountInSmallestUnit,
      data: memo
        ? encodeFunctionData({
            abi: [
              {
                type: 'function',
                name: 'transfer',
                inputs: [{type: 'string', name: 'memo'}],
              },
            ],
            functionName: 'transfer',
            args: [memo],
          })
        : '0x',
    }
  }
}

export const generateMemo = (profileId: string, sequence: number): string => {
  const shortId = profileId.slice(0, 8)
  const sequenceStr = sequence.toString().padStart(4, '0')
  const uniqueId = `${shortId}-${sequenceStr}`
  return uniqueId
}

export const defaultCoins: CoinOption[] = [
  {symbol: 'ETH', name: 'Ethereum', address: null, decimals: 18},
  {
    symbol: 'USDT',
    name: 'Tether USD',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
  },
  {symbol: 'OTHER', name: 'Custom Token', address: null, decimals: 18},
]
