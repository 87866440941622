import {CircularProgress, Stack} from '@mui/material'
import {ApplicantOnly} from 'forms'
import {Join, Membership} from 'pages'
import {useEffect, useState} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'

export function AuthLayout() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isInitializing, setIsInitializing] = useState(true)

  // Check for 'member' claim
  const {status: memberStatus, data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  // Check for 'applicant' claim
  const {status: applicantStatus, data: applicant} = useSigninCheck({
    requiredClaims: {applicant: true},
  })

  useEffect(() => {
    if (memberStatus !== 'loading' && applicantStatus !== 'loading') {
      setIsInitializing(false)

      if (member?.signedIn || applicant?.signedIn) {
        const mode = searchParams.get('mode')

        if (mode === 'signIn') {
          const newSearchParams = new URLSearchParams(searchParams)
          newSearchParams.delete('mode')
          newSearchParams.delete('oobCode')
          newSearchParams.delete('apiKey')
          newSearchParams.delete('lang')
          console.log('Redirecting to', newSearchParams.toString())
          setSearchParams(newSearchParams)
        }
      }
    }
  }, [
    searchParams,
    setSearchParams,
    member?.signedIn,
    applicant?.signedIn,
    memberStatus,
    applicantStatus,
  ])

  // While initializing, show a spinner
  if (isInitializing) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  // Helper functions to determine user roles
  const isMember = member.signedIn && member.hasRequiredClaims
  const isApplicantOnly =
    applicant.signedIn &&
    applicant.hasRequiredClaims &&
    !member.hasRequiredClaims
  const needsToApply = applicant.signedIn && !applicant.hasRequiredClaims

  // Render views based on role
  if (isMember) return <Outlet />
  if (isApplicantOnly) return <ApplicantOnly />
  if (needsToApply) return <Membership />

  // Default: User not signed in or does not meet any conditions
  return <Join />
}
