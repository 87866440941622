import {ButtonBase} from '@mui/material'
import {PancakeBioLogo} from 'assets'
import {useNavigate} from 'react-router-dom'

export function Logo() {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  return (
    <ButtonBase
      sx={{flexDirection: 'row', p: 1, borderRadius: 2, alignItems: 'center'}}
      onClick={handleNavigate}
    >
      <img
        src={PancakeBioLogo}
        alt="Pancake ID Logo"
        style={{maxWidth: 32, width: '100%', height: 'auto'}}
      />
    </ButtonBase>
  )
}
