import {CircularProgress, Stack} from '@mui/material'
import {FirebaseApp} from 'firebase/app'
import {
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
} from 'firebase/firestore'
import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage,
} from 'firebase/storage'
import React, {useEffect, useState} from 'react'
import {
  AuthProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
  useInitAuth,
  useInitFirestore,
} from 'reactfire'

export function FirestoreComponents({children}: {children: React.ReactNode}) {
  const app = useFirebaseApp()
  const [storage, setStorage] = useState<FirebaseStorage | null>(null)

  const {status: firestoreStatus, data: firestoreInstance} = useInitFirestore(
    async (firebaseApp: FirebaseApp) => {
      // Initialize Firestore with persistence
      const db = initializeFirestore(firebaseApp, {
        localCache: persistentLocalCache({}),
      })

      if (process.env.NODE_ENV !== 'production') {
        connectFirestoreEmulator(db, '0.0.0.0', 8080)
      }

      return db
    },
  )

  const {status: authStatus, data: authInstance} = useInitAuth(
    async (firebaseApp: FirebaseApp) => {
      // Initialize Auth with persistence
      const newAuth = initializeAuth(firebaseApp, {
        persistence: [indexedDBLocalPersistence],
      })

      if (process.env.NODE_ENV !== 'production') {
        connectAuthEmulator(newAuth, 'http://0.0.0.0:9099')
      }

      return newAuth
    },
  )

  useEffect(() => {
    const initStorage = () => {
      const storageInstance = getStorage(app)

      if (process.env.NODE_ENV !== 'production') {
        connectStorageEmulator(storageInstance, '0.0.0.0', 9199)
      }

      setStorage(storageInstance)
    }

    initStorage()
  }, [app])

  if (firestoreStatus === 'loading' || authStatus === 'loading' || !storage) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <AuthProvider sdk={authInstance}>
      <FirestoreProvider sdk={firestoreInstance}>
        <StorageProvider sdk={storage}>{children}</StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}
