import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import {ChainIcon} from 'connectkit'
import React from 'react'
import {useAccount, useSwitchChain} from 'wagmi'

export const NetworkSwitch: React.FC = () => {
  const {chain} = useAccount()
  const {chains, switchChain} = useSwitchChain()

  const handleChange = (event: SelectChangeEvent<string>) => {
    const chainId = parseInt(event.target.value, 10)
    if (chainId === 1 || chainId === 11155111) {
      switchChain?.({chainId: chainId})
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="chain-select-label">Network</InputLabel>
      <Select
        labelId="chain-select-label"
        id="chain-select"
        value={chain?.id.toString() || ''}
        label="Network"
        onChange={handleChange}
        MenuProps={{
          slotProps: {
            paper: {sx: {borderRadius: 0.5}},
          },
        }}
      >
        {chains.map(supportedChain => (
          <MenuItem
            key={supportedChain.id}
            value={supportedChain.id.toString()}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ChainIcon id={supportedChain.id} />
              <Typography variant="body2">{supportedChain.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
