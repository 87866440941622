import {
  ArrowBack,
  ArrowForward,
  VisibilityOffRounded,
  VisibilityRounded,
  WarningAmber,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  MemberControls,
  ProfileDonations,
  ProfileList,
  PublicProfileCard,
  SignInWithEmail,
} from 'components'
import {useModal} from 'connectkit'
import {depositState} from 'data'
import {DonateModal} from 'forms/donate/DonateModal'
import {useActiveProfileBySlug} from 'hooks'
import {useOtherPublicProfiles} from 'hooks/useOtherPublicProfiles'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'
import {useSetRecoilState} from 'recoil'
import {useAccount} from 'wagmi'

export function Member() {
  const {id} = useParams()
  const {
    data: profile,
    isLoading,
    refetch,
  } = useActiveProfileBySlug(id || undefined)
  const [showNsfw, setShowNsfw] = useState(false)
  const [showNsfwDialog, setShowNsfwDialog] = useState(false)
  const setDeposit = useSetRecoilState(depositState)
  const {setOpen} = useModal()
  const {data: profiles} = useOtherPublicProfiles(profile?.docId)
  const {isConnected} = useAccount()

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const isOwner = member?.user?.uid === profile?.userId

  const handleNsfwToggle = () => {
    if (showNsfw) {
      setShowNsfw(false)
    } else {
      setShowNsfwDialog(true)
    }
  }

  const handleConfirmAge = (isAdult: boolean) => {
    setShowNsfwDialog(false)
    if (isAdult) {
      setShowNsfw(true)
    }
  }

  const scrollToCryptoDonate = () => {
    if (!isConnected) return setOpen(true)
    if (isConnected) return setDeposit(true)
    const element = document.getElementById('crypto-donate')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  if (isLoading) return <CircularProgress />

  if (!profile)
    return (
      <Alert variant="standard" severity="error">
        Profile not found
      </Alert>
    )

  return (
    <Stack spacing={8} textAlign={'center'}>
      <Stack spacing={2}>
        <Stack alignItems={'center'}>
          <Avatar
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: 480,
              maxWidth: 480,
            }}
            src={profile.pfp}
            alt={profile.displayName}
            variant="rounded"
          />
        </Stack>
        <Typography variant="h4">{profile.displayName}</Typography>

        <Divider>
          <Button
            variant="outlined"
            onClick={scrollToCryptoDonate}
            color="success"
            startIcon={<ArrowForward />}
            endIcon={<ArrowBack />}
            size="large"
            sx={{py: 2, px: 4}}
          >
            Donate to {profile.displayName}
          </Button>
        </Divider>
        <Stack alignItems={'center'}>
          <Typography variant="body2" color="text.secondary" maxWidth={'sm'}>
            {profile.description}
          </Typography>
        </Stack>
      </Stack>

      {isOwner && (
        <MemberControls
          displayName={profile.displayName}
          slug={profile.slug}
          docId={profile.docId}
          refetch={refetch}
        />
      )}

      <Stack textAlign={'left'} spacing={2}>
        <Typography
          variant="overline"
          color="text.secondary"
          textAlign={'center'}
        >
          Links
        </Typography>

        <ProfileList links={profile.sfw} />
      </Stack>

      {profile?.nsfw && profile.nsfw.length > 0 && (
        <Stack spacing={8}>
          <Divider>
            <Tooltip title={`Oooh, I knew you wanted this stuff`}>
              <Button
                onClick={handleNsfwToggle}
                startIcon={
                  showNsfw ? <VisibilityOffRounded /> : <VisibilityRounded />
                }
                variant="outlined"
                color="error"
                size="large"
                sx={{py: 2, borderWidth: 1}}
              >
                {showNsfw
                  ? `Hide Naughty Profiles (${profile.nsfw?.length})`
                  : `View Naughty Profiles (${profile.nsfw?.length})`}
              </Button>
            </Tooltip>
          </Divider>

          {showNsfw && (
            <Stack spacing={2}>
              <Typography
                variant="overline"
                color="text.secondary"
                textAlign={'center'}
              >
                Naughty Links
              </Typography>
              <Stack textAlign={'left'}>
                <ProfileList links={profile.nsfw} nsfw />
              </Stack>
            </Stack>
          )}
        </Stack>
      )}

      {/* <CryptoTransactions /> */}
      <ProfileDonations />

      {profiles && profiles.length > 0 && (
        <Stack spacing={2}>
          <Typography
            variant="overline"
            color="text.secondary"
            textAlign={'center'}
          >
            More Profiles
          </Typography>

          <Grid2 container spacing={5} justifyContent={'center'}>
            {profiles?.map(profile => (
              <Grid2 key={profile.slug} size={12} maxWidth={480}>
                <PublicProfileCard key={profile.slug} profile={profile} />
              </Grid2>
            ))}
          </Grid2>
        </Stack>
      )}

      {!member?.hasRequiredClaims && (
        <Stack alignItems={'center'} py={10}>
          <Box maxWidth={480} width="100%" pb={2}>
            <SignInWithEmail />
          </Box>
        </Stack>
      )}

      <Dialog
        open={showNsfwDialog}
        onClose={() => setShowNsfwDialog(false)}
        slotProps={{
          root: {},
        }}
        PaperProps={{
          sx: {
            borderRadius: 0.5,
            p: 2,
            background: 'background.paper',
          },
          elevation: 2,
        }}
      >
        <DialogTitle>
          <Stack
            direction="column"
            spacing={0}
            alignItems="center"
            justifyContent={'center'}
          >
            <WarningAmber
              fontSize="large"
              color="inherit"
              sx={{color: 'text.disabled'}}
            />
            <span>Sensitive Content Warning</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography
            textAlign={'center'}
            color="text.secondary"
            variant="body2"
          >
            Some viewers might find the content protrayed shocking, it is
            intended for individuals that are of legal age, which is 18+ under
            the jurisdictions of England and Wales. Please abide by the local
            laws of your country, and proceed if you are of Legal Age to view
            Explicit Content in your country.
          </Typography>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Stack spacing={2} width="100%">
            <Button
              onClick={() => handleConfirmAge(true)}
              variant="contained"
              fullWidth
              color="success"
            >
              I am 18 or older
            </Button>
            <Button
              onClick={() => handleConfirmAge(false)}
              variant="outlined"
              color="inherit"
            >
              I am under 18
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <DonateModal
        profileName={profile.displayName}
        profileImage={profile.pfp}
      />

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          p: 2,
          pb: 4,
          zIndex: 1,
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Avatar
            sx={{width: 72, height: 72}}
            src={profile.pfp}
            alt={profile.displayName}
            variant="circular"
          />
          <Button
            variant="contained"
            onClick={scrollToCryptoDonate}
            color="success"
            size="large"
            fullWidth
            sx={{
              py: 1.6,
              fontSize: '1.25rem',
            }}
          >
            Donate to {profile.displayName}
          </Button>
          {/* <Button
            variant="outlined"
            onClick={scrollToCryptoDonate}
            color="info"
            startIcon={<CreditCard />}
            size="large"
            fullWidth
            sx={{
              py: 1.6,
            }}
          >
            Donate Cash to {profile.displayName.split(' ')[0]}
          </Button> */}
        </Stack>
        {/* <IconButton>
          <MoreVert />
        </IconButton> */}
      </Box>
    </Stack>
  )
}
