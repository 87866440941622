import {Container} from '@mui/material'
import {AppHeader, Footer} from 'components'
import {useScrollToTop} from 'hooks'
import {Outlet} from 'react-router-dom'
import {Fragment} from 'react/jsx-runtime'

export function WrapperLayout() {
  useScrollToTop()
  return (
    <Fragment>
      <AppHeader />
      <Container maxWidth="md" sx={{pt: 10, pb: 2}}>
        <Outlet />
        <Footer />
      </Container>
    </Fragment>
  )
}
