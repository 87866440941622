import {AddLinkRounded} from '@mui/icons-material'
import {Box, Button, CircularProgress, Divider, Stack} from '@mui/material'
import {PancakeMascot, SocialCard} from 'components'
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
} from 'firebase/firestore'
import {useApplication} from 'hooks'
import React from 'react'
import {FormProvider, useFieldArray, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useAuth} from 'reactfire'
import {iSocialProfile} from 'types'
import {v4 as uuidv4} from 'uuid'

type FormValues = {
  socials: iSocialProfile[]
}

const defaultSocials = [
  {
    id: uuidv4(),
    title: 'Instagram',
    href: 'https://instagram.com/',
    nsfw: false,
  },
  {id: uuidv4(), title: 'X', href: 'https://x.com/', nsfw: false},
  {id: uuidv4(), title: 'TikTok', href: 'https://tiktok.com/@', nsfw: false},
  {id: uuidv4(), title: 'YouTube', href: 'https://youtube.com/@', nsfw: false},
  {id: uuidv4(), title: 'Twitch', href: 'https://twitch.tv/', nsfw: false},
  {id: uuidv4(), title: 'OnlyFans', href: 'https://onlyfans.com/', nsfw: true},
]

export function SocialStep() {
  const {data: application, refetch, isLoading} = useApplication()
  const [, setSearchParams] = useSearchParams()
  const auth = useAuth()
  const firestore = getFirestore()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      socials: defaultSocials,
    },
  })

  const {control, reset, getValues} = methods

  React.useEffect(() => {
    if (application?.socials && application.socials.length > 0) {
      reset({socials: application.socials})
    }
  }, [application, reset])

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'socials',
  })

  const handleAddSocial = () => {
    append({id: uuidv4(), title: '', href: '', nsfw: false})
  }

  const handleRemoveSocial = (index: number) => {
    remove(index)
  }

  const submitToFirestore = async (data: FormValues) => {
    if (!auth.currentUser) return
    if (application) {
      const applicationRef = doc(firestore, 'applications', application.docId)
      const formattedData = {
        socials: data.socials.map(social => ({
          ...social,
          id: social.id || uuidv4(),
        })),
        updatedAt: Timestamp.now(),
      }

      await setDoc(applicationRef, formattedData, {merge: true})
      await refetch()
      console.log('Application document updated successfully')
    } else {
      const newApplicationRef = doc(collection(firestore, 'applications'))
      const formattedData = {
        userId: auth.currentUser.uid,
        socials: data.socials.map(social => ({
          ...social,
          id: social.id || uuidv4(),
        })),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      }

      await setDoc(newApplicationRef, formattedData)
      await refetch()
      console.log('Application document created successfully')
    }
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!auth.currentUser || isSubmitting) return
    setIsSubmitting(true)

    try {
      const formData = getValues()
      const hasExistingData =
        application?.socials && application.socials.length > 0
      const hasValidData = formData.socials.some(
        social => social.title && social.href,
      )

      if (hasValidData) {
        await submitToFirestore(formData)
        console.log('set next step 1')
        setSearchParams({mode: 'apply', step: 'details'})
      } else if (hasExistingData) {
        console.log('Using existing data, continuing to next step')
        setSearchParams({mode: 'apply', step: 'details'})
        console.log('set next step 2')
      } else {
        console.log('No valid data to submit, please fill out the form')
      }
    } catch (error) {
      console.error('Error processing application:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const hasExistingData = application?.socials && application.socials.length > 0
  const hasValidData = fields.some(field => field.title && field.href)

  if (isLoading) return <CircularProgress />

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3}>
          <PancakeMascot
            text={`${auth.currentUser?.displayName}, ... 🫦 ... 🥵 ... 🙈 ... I want to see more 👀, what are your socials? 😏`}
          />

          {fields.map((field, index) => (
            <SocialCard
              key={field.id}
              field={field}
              index={index}
              handleRemoveSocial={handleRemoveSocial}
            />
          ))}

          <Divider>
            <Button
              color="info"
              onClick={handleAddSocial}
              startIcon={<AddLinkRounded />}
            >
              Add Social Profile
            </Button>
          </Divider>

          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Button
              color="inherit"
              onClick={() => setSearchParams({mode: 'apply', step: 'account'})}
              sx={{mr: 1}}
            >
              Back
            </Button>
            <Box sx={{flex: '1 1 auto'}} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || (!hasValidData && !hasExistingData)}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}
