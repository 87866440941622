import {Avatar, Box, Modal, Stack, Typography} from '@mui/material'
import {ConnectKitButton} from 'connectkit'
import {depositState} from 'data'
import {DonationWizard} from 'forms/donation'
import React from 'react'
import {useRecoilState} from 'recoil'
import {useAccount} from 'wagmi'

export interface Asset {
  token_address: string | null
  symbol: string
  balance: string
  decimals: number
}

interface DonateModalProps {
  profileName: string
  profileImage?: string
}

export const DonateModal: React.FC<DonateModalProps> = ({
  profileName,
  profileImage,
}) => {
  const [isOpen, setIsOpen] = useRecoilState(depositState)
  const handleClose = () => setIsOpen(false)
  const {isConnected} = useAccount()

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          width: '92vw',
          maxWidth: 480,
          p: 3,
          backgroundColor: 'background.paper',
          borderRadius: 0.5,
        }}
      >
        <Avatar
          sx={{
            width: 148,
            height: 148,
            marginTop: -13,
            mx: 'auto',
            borderRadius: 0.5,
          }}
          src={profileImage}
          alt={profileName}
          variant="rounded"
        />
        <Stack spacing={1.5} my={1} alignItems="center">
          <Typography variant="h6" component="h2" textAlign={'center'}>
            Donating to {profileName}
          </Typography>
          {isConnected && <ConnectKitButton />}
          <DonationWizard />
        </Stack>
      </Box>
    </Modal>
  )
}
