import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

interface CoinbasePriceResponse {
  data: {
    base: string
    currency: string
    amount: string
  }
}

async function getAssetPriceInUSD(assetSymbol?: string): Promise<number> {
  try {
    if (!assetSymbol) return 0
    const response = await axios.get<CoinbasePriceResponse>(
      `https://api.coinbase.com/v2/prices/${assetSymbol}-USD/spot`,
    )

    return parseFloat(response.data.data.amount)
  } catch (error) {
    console.error(`Error fetching price for ${assetSymbol}:`, error)
    throw error
  }
}

export function useAssetPrice(symbol: string) {
  return useQuery<number, Error>({
    queryKey: ['assetPrice', symbol],
    queryFn: () => getAssetPriceInUSD(symbol),
    staleTime: 60 * 1000, // 1 minute
    refetchInterval: 60 * 1000, // Refetch every minute
    enabled: !!symbol,
  })
}
