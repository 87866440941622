import {Stack} from '@mui/material'
import {Logo} from './Logo'
import {Navigation} from './Navigation'

export function AppHeader() {
  return (
    <Stack
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
      position={'fixed'}
      sx={{
        background:
          'linear-gradient(to bottom, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
        backdropFilter: 'blur(5px)',
        borderTopRightRadius: 24,
        borderBottomLeftRadius: 24,
        px: 2,
        py: 2,
        zIndex: 5,
        width: '100%',
      }}
    >
      <Logo />
      <Navigation />
    </Stack>
  )
}
