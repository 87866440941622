import {Avatar, Box, CircularProgress, Stack, Typography} from '@mui/material'
import {useParams} from 'react-router-dom'
import {useAccount} from 'wagmi'

import {ConnectKitButton} from 'connectkit'
import {DonationWizard} from 'forms'
import {useActiveProfileBySlug} from 'hooks'

// 0xad8a0edf46c296228f986876a544114d2b9d4e1a
// 0x0426fd19ff52c78550d88100b70dfd40fe1345c2

// export function MemberDonate2() {
//   const {id} = useParams<{id: string}>()
//   const {data: member, isLoading: isMemberLoading} = useActiveProfileBySlug(id)
//   const {isConnected, address} = useAccount()
//   const {setOpen} = useModal()
//   const {assets, loading: isAssetsLoading} = useUserBalances()
//   const {data: balance, isLoading: isBalanceLoading} = useBalance({address})

//   const renderContent = () => {
//     if (isMemberLoading) {
//       return <Typography>Loading member data...</Typography>
//     }

//     if (!member) {
//       return (
//         <Typography>
//           Member not found. Please check the ID and try again.
//         </Typography>
//       )
//     }

//     if (!isConnected) {
//       return (
//         <Stack spacing={2} alignItems="center">
//           <Typography>Please connect your wallet to donate.</Typography>
//           <Button onClick={() => setOpen(true)} variant="contained">
//             Connect Wallet
//           </Button>
//         </Stack>
//       )
//     }

//     if (isAssetsLoading || isBalanceLoading) {
//       return <Typography>Loading wallet data...</Typography>
//     }

//     const hasFunds =
//       assets.some(asset => parseFloat(asset.balance) > 0) ||
//       (balance && balance.value > 0)

//     if (!hasFunds) {
//       return (
//         <Stack spacing={2}>
//           <Typography>You don't have any funds in your wallet.</Typography>
//           <TokenAddressLookup />
//           <Button variant="outlined" onClick={() => setOpen(true)}>
//             Change Wallet
//           </Button>
//           <Button variant="outlined">Change Network</Button>
//         </Stack>
//       )
//     }

//     return (
//       <Stack spacing={2}>
//         <Typography variant="h5">
//           Donate to {member.displayName} (ID: {id})
//         </Typography>
//         <DonateForm profileId={member.docId} profileName={member.displayName} />
//         <ChainSwitcher />
//         <AssetPicker />
//         <TokenAddressLookup />
//       </Stack>
//     )
//   }

//   return (
//     <Stack spacing={4}>
//       {renderContent()}
//       <DebugInfo member={member} />
//     </Stack>
//   )
// }

// const DebugInfo2 = ({title, data}: {title: string; data: any}) => (
//   <Box>
//     <Typography variant="caption">{title}</Typography>
//     <Box
//       sx={{
//         overflow: 'scroll',
//         fontSize: '0.75rem',
//         backgroundColor: 'background.default',
//         color: 'text.secondary',
//         px: 1,
//         maxHeight: 200,
//       }}
//     >
//       <pre>{JSON.stringify(data, null, 2)}</pre>
//     </Box>
//   </Box>
// )

// const NoMemberIdUI = () => <Home />
// const NoMemberUI = () => <Home />

// const ConnectWalletUI = () => (
//   <Stack>
//     <ConnectKitButton />
//     <Typography>Please connect your wallet to continue.</Typography>
//   </Stack>
// )

// const DonateUI = () => (
//   <Stack>
//     <ConnectKitButton />
//     <Typography>Donation UI goes here.</Typography>
//   </Stack>
// )

// const NoMoneyUI = () => (
//   <Stack>
//     <ConnectKitButton />
//     <Typography>Insufficient balance to make a donation.</Typography>
//   </Stack>
// )

const LoadingUi = () => (
  <Stack alignItems={'center'}>
    <CircularProgress />
  </Stack>
)

export function MemberDonate() {
  const {id: memberSlug} = useParams<{id: string}>()
  const {data: member, isLoading} = useActiveProfileBySlug(memberSlug || '')
  const {isConnected} = useAccount()

  return (
    <Stack position="relative">
      <Box sx={{}}>
        <Avatar
          sx={{
            width: 148,
            height: 148,
            mx: 'auto',
            borderRadius: 0.5,
          }}
          src={member?.pfp}
          alt={member?.displayName}
          variant="rounded"
        />
        <Stack spacing={2} alignItems={'center'}>
          <Typography variant="h6" component="h2" textAlign={'center'}>
            Donating to {member?.displayName}
          </Typography>
          {isConnected && <ConnectKitButton />}
          {isLoading ? <LoadingUi /> : <DonationWizard />}
        </Stack>
      </Box>
    </Stack>
  )
}

// export function MemberDonate3() {
//   const {id: memberSlug} = useParams<{id: string}>()
//   const {data: member, isLoading} = useActiveProfileBySlug(memberSlug || '')
//   const {isConnected, address, chain} = useAccount()

//   const {data: balance} = useBalance({address})

//   // Placeholder for donation amount limits
//   const minDonation = '0.01'
//   const maxDonation = '10'

//   const hasBalance = balance && balance.value > BigInt(0)

//   if (!memberSlug) return <NoMemberIdUI />
//   if (isLoading) return <LoadingUi />
//   if (!member) return <NoMemberUI />

//   return (
//     <Stack pr={24}>
//       <Stack sx={{position: 'fixed', right: 24, top: 80, width: 360}}>
//         <Paper sx={{p: 2, borderRadius: 0.5}}>
//           <Stack spacing={1}>
//             <Typography variant="overline">DEBUG</Typography>
//             {member && (
//               <DebugInfo2
//                 title="Member Information"
//                 data={{
//                   DocumentParams: {MemberSlug: memberSlug},
//                   DatabaseMember: {
//                     ...member,
//                   },
//                 }}
//               />
//             )}
//             {isConnected && (
//               <DebugInfo2
//                 title="Wallet Information"
//                 data={{
//                   chain,
//                   account: address,
//                 }}
//               />
//             )}
//             {isConnected && (
//               <DebugInfo2
//                 title="Balance Information"
//                 data={{
//                   balance: balance?.formatted,
//                   symbol: balance?.symbol,
//                   minDonation,
//                   maxDonation,
//                 }}
//               />
//             )}
//           </Stack>
//         </Paper>
//       </Stack>
//       <Box>
//         <Typography variant="h4">Donate</Typography>
//         {!isConnected && <ConnectWalletUI />}

//         {isConnected && !hasBalance && <NoMoneyUI />}
//         {isConnected && hasBalance && <DonateUI />}
//       </Box>

//       <Box>
//         <dl>
//           <dt>NoMemberIdUi</dt>
//           <dd>
//             {`const {id: memberSlug} = useParams<{id: string}>()`}
//             No Member ID Found in URL
//             <br />
//             <em>- fallback display memberlist</em>
//           </dd>
//           <dt>NoMemberUi</dt>
//           <dd>
//             {`const member = useActiveProfileBySlug(memberSlug || undefined)`}
//             No Member Found for the Given ID
//             <br />
//             <em>- fallback display memberlist</em>
//           </dd>
//           <dt>ConnectWallet</dt>
//           <dd>
//             User is not connected to the blockchain{' '}
//             <dl>
//               <dt>{`const {isConnected}`} = useAccount()</dt>
//               <dd>Check if user is connected</dd>
//               <dt>{`const {setOpen} = useModal()`}</dt>
//               <dd>Open the modal and connect to Mainnet</dd>
//             </dl>
//           </dd>
//           <dt>NoMoneyUi</dt>
//           <dd>
//             User does not have funds in their wallet to donate
//             <dl>
//               <dt>{`const {data: balance} = useBalance({address, chain: chainId, token})`}</dt>
//               <dd>get the balance of the user</dd>
//               <dt>{`balance?.value > 0`}</dt>
//               <dd>check the balance is greater than 0</dd>
//             </dl>
//           </dd>
//           <dt>DonateUi</dt>
//           <dd>
//             User has funds and can make a donation
//             <dl>
//               <dt>{`const {data: balance} = useBalance({address, chain: chainId, token})`}</dt>
//               <dd>get the balance of the user</dd>
//               <dt>{`balance?.value > 0`}</dt>
//               <dd>check the balance is greater than 0</dd>
//               <dt>{`donation max = balance.value - gasFee`}</dt>
//               <dd>
//                 ensure the user has enough funds to cover transaction and gas
//                 fees
//               </dd>
//               <dt>{`donation min = 0.01`}</dt>
//               <dd>anything smaller just doesn't make sense.</dd>
//               <dt>{`memo = docId:::{user message to profile}`}</dt>
//               <dd>the query identifier and message to a profile</dd>
//             </dl>
//           </dd>
//           <dt>DonatingUi</dt>
//           <dd>
//             User has a donation pending{' '}
//             <dl>
//               <dt>{`transactionSend && wait for receipt`}</dt>
//               <dd>wait for the donation to complete</dd>
//             </dl>
//           </dd>
//           <dt>DonatedUi</dt>
//           <dd>
//             User has successfully donated{' '}
//             <dd>
//               <dl>
//                 <dt>{`receipt confirmed`}</dt>
//                 <dd>show th receipt</dd>
//               </dl>
//             </dd>
//           </dd>
//           <dt>DonationFailed</dt>
//           <dd>
//             User's donation was not successful{' '}
//             <dd>
//               <dl>
//                 <dt>{`receipt or transaction failed - user declined or failed or whatever`}</dt>
//                 <dd>get the user to try again</dd>
//               </dl>
//             </dd>
//           </dd>
//           <dt>DonationOptions</dt>
//           <dd>
//             <dl>
//               <dt>Chain</dt>
//               <dd>The network the user is connected to & switch</dd>
//               <dt>Account</dt>
//               <dd>Wallet address of the user & switch</dd>
//               <dt>
//                 Asset(s) - <em>found by index</em>
//               </dt>
//               <dd>Tokens (Defined) & Basecoins for a user</dd>
//               <dt>Custom Token Address</dt>
//               <dd>
//                 Custom Token address defined by user, a check for a balance
//                 greater than 0
//               </dd>
//             </dl>
//           </dd>
//         </dl>
//       </Box>
//     </Stack>
//   )
// }
