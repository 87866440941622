import {Box, Paper, Stack, Typography} from '@mui/material'
import {PancakeBioLogo} from 'assets'

export function PancakeMascot({img, text}: {img?: string; text: string}) {
  return (
    <Stack direction="column" spacing={2} alignItems={'center'}>
      <Box
        sx={{
          width: 94,
          height: 94,
          margin: '0 auto !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={img ?? PancakeBioLogo}
          style={{width: '100%', height: 'auto'}}
        />
      </Box>
      <Paper sx={{borderRadius: 3}} elevation={0}>
        <Box p={2} px={4} maxWidth={360}>
          <Typography
            textAlign={'center'}
            variant="body1"
            color="text.secondary"
          >
            {text}
          </Typography>
        </Box>
      </Paper>
    </Stack>
  )
}
