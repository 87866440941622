import {yupResolver} from '@hookform/resolvers/yup'
import {ArrowBack, ArrowForward} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Fade,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import {PancakeEmail, PancakeTip} from 'assets'
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from 'firebase/auth'
import {useEffect, useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import * as yup from 'yup'
import {RoundInput} from './RoundInput'

interface ISignInWithEmailProps {
  email: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(`Really, who are you trying to fool? This is not a valid email.`)
    .required(`Aww, c'mon! I need your email address.`),
})

const actionCodeSettings = {
  url: import.meta.env.PROD
    ? 'https://pancake.bio/join?mode=signIn'
    : 'http://127.0.0.1:5173/join?mode=signIn',
  handleCodeInApp: true,
}

export function SignInWithEmail() {
  const {control, handleSubmit} = useForm<ISignInWithEmailProps>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: '',
    },
  })

  const auth = getAuth()
  const [emailRequired, setEmailRequired] = useState(false)
  const [notification, setNotification] = useState<string | null>(null)
  const [showEmailForm, setShowEmailForm] = useState(true)

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        setEmailRequired(true)
      } else {
        completeSignIn(email)
      }
    }
  }, [auth])

  const completeSignIn = async (email: string) => {
    try {
      await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      setNotification(`Captain, We're into the system!`)
      setShowEmailForm(false)
    } catch (error) {
      console.error(`Oh, Noes, Some Error?!`, error)
      setNotification((error as Error).message)
    }
  }

  const onSubmit: SubmitHandler<ISignInWithEmailProps> = async data => {
    if (emailRequired) {
      completeSignIn(data.email)
    } else {
      try {
        await sendSignInLinkToEmail(auth, data.email, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', data.email)
        setNotification(`Sent Nudes, check your inbox!`)
        setShowEmailForm(false)
      } catch (error) {
        console.error(`Oh, Noes, Some Error?!`, error)
        setNotification((error as Error).message)
      }
    }
  }

  const clearNotification = () => {
    setNotification(null)
    setShowEmailForm(true)
  }

  return (
    <Stack spacing={1}>
      <Fade in={showEmailForm} timeout={500}>
        <Stack spacing={1} style={{display: showEmailForm ? 'flex' : 'none'}}>
          <PancakeMascotImage src={PancakeTip} alt="Join Pancake today" />
          <Typography variant="h3">
            Content Creators, want to earn{' '}
            <Typography component="b" variant="inherit" color="success.main">
              more money?
            </Typography>
          </Typography>
          <Box pt={2} />
          <Typography variant="body2" color="textSecondary">
            {emailRequired
              ? 'Confirm your email to continue with profile creation'
              : 'Enter your email below to create your online profile'}
          </Typography>
          <Box pt={2} />
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            spacing={2}
            textAlign={'center'}
          >
            <RoundInput
              name="email"
              control={control}
              label="Email Address"
              rules={{required: 'Email is required'}}
              required
              fullWidth
              variant="outlined"
              endAdornment={({hasError, showSuccess}) => (
                <IconButton
                  type="submit"
                  disabled={hasError}
                  sx={{
                    backgroundColor: showSuccess ? 'success.main' : 'inherit',
                    '&:hover': {
                      backgroundColor: showSuccess ? 'success.dark' : 'inherit',
                    },
                  }}
                >
                  <ArrowForward />
                </IconButton>
              )}
            />
            {notification && (
              <Box>
                <Alert
                  severity={emailRequired ? 'info' : 'success'}
                  variant="outlined"
                >
                  {notification}
                </Alert>
              </Box>
            )}
          </Stack>
        </Stack>
      </Fade>
      <Fade in={!showEmailForm} timeout={500}>
        <Stack spacing={1} style={{display: showEmailForm ? 'none' : 'flex'}}>
          <PancakeMascotImage src={PancakeEmail} alt="Check your inbox" />
          <Typography variant="h3">
            I've sent you a nude, check your{' '}
            <Typography component="b" variant="inherit" color="success.main">
              inbox!
            </Typography>
          </Typography>
          <Box pt={2} />
          <Typography variant="body2" color="textSecondary">
            Please check your email for a login link.
          </Typography>
          <Box pt={2} />
          <Button
            onClick={clearNotification}
            variant="outlined"
            color="inherit"
            fullWidth
            startIcon={<ArrowBack />}
            size="large"
          >
            Change Email
          </Button>
        </Stack>
      </Fade>
    </Stack>
  )
}

function PancakeMascotImage({src, alt}: {src: string; alt: string}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 168,
        height: 168,
        margin: '0 auto !important',
      }}
    >
      <img src={src} alt={alt} style={{width: '100%', height: 'auto'}} />
    </Box>
  )
}
