import {Box, ButtonBase, Checkbox, Typography, styled} from '@mui/material'
import React from 'react'
import {Control, useController} from 'react-hook-form'

interface RoundedCheckboxProps {
  name: string
  control: Control<any>
  label: string
}

const StyledBox = styled(Box)(({theme}) => ({
  border: '1px solid',
  borderColor: 'white',
  borderRadius: theme.spacing(999),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  textAlign: 'left',
  padding: theme.spacing(2),
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  transition: theme.transitions.create(['border-color', 'background-color']),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.selected': {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  '&.error': {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}))

export function RoundedCheckbox({name, control, label}: RoundedCheckboxProps) {
  const {
    field: {onChange, value, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
    defaultValue: false,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  const handleClick = (event: React.MouseEvent) => {
    // Prevent the click event from reaching the ButtonBase
    event.preventDefault()
    onChange(!value)
  }

  return (
    <ButtonBase
      component="div"
      focusRipple
      onClick={handleClick}
      sx={{
        borderRadius: 999,
      }}
    >
      <StyledBox
        className={`${value ? 'selected' : ''} ${error ? 'error' : ''}`}
      >
        <Checkbox
          checked={value}
          onChange={handleChange}
          inputRef={ref}
          color={value ? 'success' : 'default'}
          onClick={e => e.stopPropagation()}
        />
        <Typography variant="body1">{label}</Typography>
      </StyledBox>
    </ButtonBase>
  )
}
