import {ArrowBack, Close, Settings} from '@mui/icons-material'
import {
  ButtonBase,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {ChainIcon} from 'connectkit'
import {depositCustomTokenState, depositOptionsState} from 'data'
import {useUserBalances} from 'hooks'
import {useRecoilState, useRecoilValue} from 'recoil'
import {formatUnits, parseUnits} from 'viem'
import {mainnet} from 'viem/chains'
import {useAccount, useBalance, useEnsName} from 'wagmi'

export function DonateOptionsButton() {
  const {address, chain} = useAccount()
  const {data: ensName} = useEnsName({address, chainId: mainnet.id})
  const {assets, loading} = useUserBalances()
  const [isOptions, setIsOptions] = useRecoilState(depositOptionsState)
  const selectedAsset = useRecoilValue(depositCustomTokenState)

  const {data: selectedBalance, isLoading: isBalanceLoading} = useBalance({
    address: address,
    token: selectedAsset as `0x${string}`,
  })

  const truncateAddress = (addr: `0x${string}` | undefined) => {
    if (!addr) return ''
    return `${addr.slice(0, 4)}...${addr.slice(-4)}`
  }

  const mainAsset = assets && assets.length > 0 ? assets[0] : null

  const safelyParseBalance = (
    value: string | number,
    decimals: number,
  ): bigint => {
    if (typeof value === 'number') {
      // If it's a number, we assume it's already in the correct units
      return parseUnits(value.toString(), decimals)
    } else if (typeof value === 'string') {
      // If it's a string, we need to determine if it's a whole number or decimal
      if (value.includes('.')) {
        return parseUnits(value, decimals)
      } else {
        // If it's a whole number string, we assume it's in the smallest units
        return BigInt(value)
      }
    }
    throw new Error('Invalid balance format')
  }

  const displayBalance =
    selectedBalance ||
    (mainAsset && {
      value: safelyParseBalance(mainAsset.balance, mainAsset.decimals),
      decimals: mainAsset.decimals,
      symbol: mainAsset.symbol,
    })

  const formatBalance = (balance: typeof displayBalance) => {
    if (!balance) return 'No balance'
    try {
      const formattedValue = formatUnits(balance.value, balance.decimals)
      const numericValue = parseFloat(formattedValue)
      return `${numericValue.toFixed(4)} ${balance.symbol}`
    } catch (error) {
      console.error('Error formatting balance:', error)
      return 'Error'
    }
  }

  return (
    <Tooltip title="Advanced Options">
      <ButtonBase
        sx={{width: '100%', borderRadius: 999, textAlign: 'left'}}
        onClick={() => setIsOptions(!isOptions)}
      >
        <Paper sx={{width: '100%', p: 1, pr: 2}}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Stack direction="row" spacing={2} alignItems={'center'}>
              {isOptions ? (
                <ArrowBack sx={{fontSize: 32}} />
              ) : (
                <ChainIcon id={chain?.id} size={32} />
              )}
              <Stack>
                <Typography variant="body2">
                  {ensName || truncateAddress(address)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {chain?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent={'flex-end'}
              direction="row"
              spacing={2}
            >
              {loading || isBalanceLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Stack alignItems="flex-end">
                  <Typography variant="body2" fontWeight="bold">
                    {formatBalance(displayBalance)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Balance
                  </Typography>
                </Stack>
              )}
              {!isOptions ? (
                <Settings color="disabled" />
              ) : (
                <Close color="disabled" />
              )}
            </Stack>
          </Stack>
        </Paper>
      </ButtonBase>
    </Tooltip>
  )
}
