import {EvmChain} from '@moralisweb3/common-evm-utils'
import {ArrowBack, ArrowForward} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import {useModal} from 'connectkit'
import {depositState} from 'data'
import dayjs from 'dayjs'
import {
  decodeTransactionInput,
  ExtendedWalletTx,
  useActiveProfileBySlug,
  useWalletTransactions,
} from 'hooks'
import {useAssetPrice} from 'hooks/useAssetPrice'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {formatEther} from 'viem'
import {useAccount, useChainId, useChains} from 'wagmi'

export function ProfileDonations() {
  const {id} = useParams()
  const chainId = useChainId()
  const chains = useChains()
  const chain = chains.find(c => c.id === chainId)
  const {data: profile} = useActiveProfileBySlug(id || undefined)

  const {
    data: transactions,
    isLoading,
    isError,
  } = useWalletTransactions({
    address: import.meta.env.VITE_WALLET_ADDRESS,
    chain: chainId === 1 ? EvmChain.ETHEREUM : EvmChain.SEPOLIA,
    uid: profile?.docId,
  })
  const setDeposit = useSetRecoilState(depositState)
  const {setOpen} = useModal()
  const {isConnected} = useAccount()

  const {data: price} = useAssetPrice('ETH')

  const scrollToCryptoDonate = () => {
    if (!isConnected) return setOpen(true)
    if (isConnected) return setDeposit(true)
    const element = document.getElementById('crypto-donate')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  const DonateButton = () => (
    <Stack alignItems={'center'} pt={4}>
      <Button
        variant="outlined"
        onClick={scrollToCryptoDonate}
        color="success"
        startIcon={<ArrowForward />}
        endIcon={<ArrowBack />}
        size="large"
        sx={{py: 2, px: 4}}
      >
        Donate to {profile?.displayName}
      </Button>
    </Stack>
  )

  if (isLoading) return <CircularProgress />

  if (isError)
    return <Alert severity="error">Error fetching transactions</Alert>

  if (transactions?.length === 0) {
    return (
      <Stack py={12} spacing={3}>
        <Typography variant="h6" textAlign={'center'}>
          {profile?.displayName}'s Donations
        </Typography>
        <Divider>
          <PancakeBankPill txs={transactions} />
        </Divider>
        <DonateButton />
      </Stack>
    )
  }

  return (
    <Stack py={12} spacing={3}>
      <Typography variant="h6" textAlign={'center'}>
        {profile?.displayName}'s Donations
      </Typography>
      <Divider>
        <PancakeBankPill txs={transactions} />
      </Divider>
      <DonateButton />

      {transactions?.map(tx => {
        const date = dayjs(tx.block_timestamp).format('DD MMM YYYY HH:MM a')
        const donation = formatEther(BigInt(tx.value))
        const usdValue = (parseFloat(donation) * (price || 0)).toFixed(2)
        const msg = tx.input ? decodeTransactionInput(tx.input)?.message : ''
        const from = tx.from_address
        const fromLabel = tx.from_address_label
        const fromName = fromLabel || from.slice(0, 4) + '...' + from.slice(-4)
        return (
          <Stack key={tx.hash} textAlign={'center'} spacing={1}>
            <Stack>
              <Divider>
                <Chip label={date} />
              </Divider>
            </Stack>
            <Stack>
              <Typography variant="h6">{donation} eth</Typography>
              <Typography variant="caption" color="success">
                ${usdValue} usd
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              {msg}
            </Typography>
            <Typography variant="body2">
              <Link
                href={`${chain?.blockExplorers?.default.url}/tx/${tx.hash}`}
                target="_blank"
              >
                {fromName}
              </Link>
            </Typography>
          </Stack>
        )
      })}
      <DonateButton />
    </Stack>
  )
}

function PancakeBankPill({txs}: {txs: ExtendedWalletTx[] | undefined}) {
  const {data: ethPrice} = useAssetPrice('ETH')

  const {totalEth, totalUsd} = useMemo(() => {
    if (!txs || !ethPrice) return {totalEth: 0, totalUsd: 0}

    const total = txs.reduce((acc, tx) => {
      const ethValue = parseFloat(formatEther(BigInt(tx.value)))
      return acc + ethValue
    }, 0)

    return {
      totalEth: total.toFixed(4),
      totalUsd: (total * ethPrice).toFixed(2),
    }
  }, [txs, ethPrice])

  return (
    <Stack alignItems="center">
      <Paper
        elevation={0}
        sx={{borderColor: `divider`, borderWidth: 1, borderStyle: 'solid'}}
      >
        <Box p={2} textAlign={'center'} minWidth={260}>
          <Typography variant="overline" color="text.disabled">
            Total Donations
          </Typography>
          <Typography variant="h5" className="font-bold">
            {totalEth} ETH
          </Typography>
          <Typography variant="subtitle1" color="success">
            ${totalUsd} USD
          </Typography>
        </Box>
      </Paper>
    </Stack>
  )
}
