export const RoundInputSx = (
  getBorderColor: () => string,
  getColor: () => string,
  textAlign: string = 'center',
) => {
  return {
    '.MuiOutlinedInput-notchedOutline legend': {display: 'none'},
    '& .MuiOutlinedInput-root': {
      borderColor: getBorderColor(),
      '& fieldset': {
        borderColor: getBorderColor(),
      },
      '&:hover fieldset': {
        borderColor: getBorderColor(),
      },
      '&.Mui-focused fieldset': {
        borderColor: getBorderColor(),
      },
    },
    '& .MuiInputLabel-root': {
      textAlign: 'center',
      color: getColor(),
      '&.Mui-focused': {
        color: getBorderColor(),
      },
      '&.Mui-error': {},
    },
    '& .MuiInputBase-input': {
      textAlign,
      color: getColor(),
    },
    '& .MuiFormHelperText-root': {
      textAlign,
      color: getColor(),
    },
    '& .MuiInputLabel-shrink': {
      margin: '0 auto',
      position: 'absolute',
      right: '0',
      left: '0',
      top: '-3px',
      width: '150px', // Need to give it a width so the positioning will work
      background: '#1A1A1A', // display: "none" //if you want to hide it completly
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      background: 'none',
      '& legend ': {
        display: 'none',
      },
    },
  }
}
