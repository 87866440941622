import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import {depositAssetState} from 'data'
import {useUserBalances} from 'hooks'
import React, {useEffect} from 'react'
import {useRecoilState} from 'recoil'

export const CoinPicker: React.FC = () => {
  const {assets, loading} = useUserBalances()
  const [selectedAsset, setSelectedAsset] = useRecoilState(depositAssetState)

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedAddress = event.target.value
    setSelectedAsset(selectedAddress)
    const asset = assets.find(
      a =>
        a.token_address === selectedAddress ||
        (a.token_address === null && selectedAddress === 'native'),
    )
    if (asset) {
      console.log(asset)
      // onAssetSelect(asset)
    }
  }

  useEffect(() => {
    if (assets.length > 0 && selectedAsset === '') {
      setSelectedAsset(assets[0].token_address || 'native')
    }
  }, [assets])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="asset-select-label">Wallet Coins</InputLabel>
      <Select
        labelId="asset-select-label"
        value={selectedAsset}
        label="Coin"
        onChange={handleChange}
        MenuProps={{
          slotProps: {
            paper: {sx: {borderRadius: 0.5}},
          },
        }}
        sx={{px: 2}}
        variant="outlined"
      >
        {assets.map(asset => (
          <MenuItem
            key={asset.token_address || 'native'}
            value={asset.token_address || 'native'}
          >
            <Stack>
              <Typography>{parseFloat(asset.balance).toFixed(4)}</Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="caption" color="text.secondary">
                  {asset.symbol}{' '}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {asset.token_address ? asset.token_address : '0x0'}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
