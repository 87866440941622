import {collection, doc, Firestore, setDoc, Timestamp} from 'firebase/firestore'
import {v4 as uuidv4} from 'uuid'

export type Steps =
  | 'terms'
  | 'displayName'
  | 'photoUrl'
  | 'socials'
  | 'details'
  | 'ids'
  | 'payments'
  | 'finish'

export const createApplication = async (
  userId: string,
  data: any,
  firestore: Firestore,
) => {
  if (data.termsAccepted !== true) return new Error('Terms not accepted')
  const newApplicationRef = doc(collection(firestore, 'applications'))
  const formattedData = {
    ...data,
    // meta data
    createdAt: Timestamp.now(),
    createdBy: userId,
    updatedAt: Timestamp.now(),
    updatedBy: userId,

    // term metadata ::toDO: break out into own collection @KaiLong
    termsAccepted: data.termsAccepted,
    status: 'new',

    // ids
    docId: newApplicationRef.id,
    userId: userId,

    // step tracker
    currentStep: 'displayName' as Steps,
  }

  await setDoc(newApplicationRef, formattedData)
  console.log('Application document created successfully')
  return newApplicationRef.id
}

export const updateApplication = async (
  applicationId: string,
  data: any,
  firestore: Firestore,
) => {
  const applicationRef = doc(firestore, 'applications', applicationId)
  const formattedData = {
    ...data,
    updatedAt: Timestamp.now(),
  }

  await setDoc(applicationRef, formattedData, {merge: true})
  console.log('Application document updated successfully')
}

export const formatSocials = (socials: any[]) => {
  return socials.map(social => ({
    ...social,
    id: social.id || uuidv4(),
  }))
}
