import {yupResolver} from '@hookform/resolvers/yup'
import {DocumentScannerOutlined} from '@mui/icons-material'
import {Button, Link, Stack, Typography} from '@mui/material'
import {useQueryClient} from '@tanstack/react-query'
import {PancakeMascot, RoundedCheckbox} from 'components'
import {useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useAuth, useFirestore} from 'reactfire'
import {ROUTES} from 'router'
import * as yup from 'yup'
import {createApplication} from './services'

interface FormData {
  termsAccepted: boolean
}

const schema = yup.object().shape({
  termsAccepted: yup
    .boolean()
    .required('This field is required')
    .oneOf([true], 'You must accept the terms of service'),
})

export function TermsStep() {
  const [, setSearchParams] = useSearchParams()
  // const {refetch} = useApplication()
  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting, isValid},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      termsAccepted: false,
    },
  })

  const auth = useAuth()
  const firestore = useFirestore()
  const queryClient = useQueryClient()

  const onSubmit = async (data: FormData) => {
    if (!auth.currentUser) return
    // if (application && application.termsAccepted)
    //   return setSearchParams({mode: 'apply', step: 'displayName'})
    try {
      await createApplication(
        auth.currentUser.uid,
        {
          termsAccepted: data.termsAccepted,
        },
        firestore,
      )
      console.log('Terms accepted and application created')
      queryClient.invalidateQueries({
        queryKey: ['application', auth.currentUser.uid],
      })
      setSearchParams({mode: 'apply', step: 'displayName'})
    } catch (error) {
      console.error('Error creating application:', error)
    }
  }

  return (
    <Stack
      spacing={3}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      alignItems="center"
    >
      <PancakeMascot text="So, before we can continue, I need you to accept my terms of service." />

      <Button
        variant="outlined"
        color="info"
        fullWidth
        startIcon={<DocumentScannerOutlined />}
        size="large"
        sx={{py: 1.5}}
        LinkComponent={Link}
        href={ROUTES.TERMS}
        target="_blank"
      >
        Pancake Bio's Terms of Service
      </Button>

      <RoundedCheckbox
        name="termsAccepted"
        control={control}
        label="I have read and accept Pancake Bio's terms of service"
      />

      {errors.termsAccepted && (
        <Typography variant="body2" color="error">
          {errors.termsAccepted.message}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="success"
        disabled={isSubmitting || !isValid}
        size="large"
        sx={{py: 1.5}}
        fullWidth
      >
        {!isValid ? `Accept Terms to Continue` : `Continue`}
      </Button>
    </Stack>
  )
}
