import {TextField, TextFieldProps} from '@mui/material'
import React, {ReactNode, useCallback} from 'react'
import {
  FieldValues,
  Path,
  UseControllerProps,
  useController,
} from 'react-hook-form'
import {RoundInputSx} from './RoundInputSx'

type ReusableInputProps<TFieldValues extends FieldValues> = Omit<
  TextFieldProps,
  'name'
> & {
  name: Path<TFieldValues>
  control: UseControllerProps<TFieldValues>['control']
  rules?: UseControllerProps<TFieldValues>['rules']
  endAdornment?: ReactNode | ((props: EndAdornmentProps) => ReactNode)
}

interface EndAdornmentProps {
  hasError: boolean
  showSuccess: boolean
}

export function RoundInput<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  label,
  endAdornment,
  ...props
}: ReusableInputProps<TFieldValues>) {
  const {
    field,
    fieldState: {error, isTouched, isDirty},
    formState: {isSubmitted, isValid},
  } = useController({
    name,
    control,
    rules,
  })

  const hasError = !!error && (isTouched || isSubmitted)
  const isSuccess = isDirty && !hasError && isValid
  const showSuccess = isSuccess || (isSubmitted && !hasError)

  const renderEndAdornment = () => {
    if (typeof endAdornment === 'function') {
      return endAdornment({hasError, showSuccess})
    }
    if (React.isValidElement(endAdornment)) {
      return endAdornment
    }
    return null
  }

  const getBorderColor = useCallback(() => {
    if (hasError) return 'error.main'
    if (showSuccess) return 'success.main'
    return 'white'
  }, [hasError, showSuccess])

  const getColor = useCallback(() => {
    if (hasError) return 'error.main'
    if (showSuccess) return 'success.main'
    return 'white'
  }, [hasError, showSuccess])

  return (
    <TextField
      {...field}
      {...props}
      name={name}
      label={label}
      error={hasError}
      sx={{...RoundInputSx(getBorderColor, getColor), ...props.sx}}
      helperText={error ? error.message : ''}
      slotProps={{
        input: {
          endAdornment: renderEndAdornment(),
        },
        ...props.slotProps,
      }}
    />
  )
}
