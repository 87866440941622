import {Box, ButtonBase, Link, Stack, Tooltip, Typography} from '@mui/material'
import {getIconForSocialMedia} from './ProfileIconMapper'

interface ProfileListProps {
  nsfw?: boolean
  links?: Array<{id: string; title: string; href: string; nsfw: boolean}>
}

const parseUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url)
    const pathSegments = parsedUrl.pathname.split('/').filter(Boolean)
    return `${pathSegments[pathSegments.length - 1]}`
  } catch (error) {
    return url
  }
}

export function ProfileList({links, nsfw}: ProfileListProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={'center'}
      flexWrap={'wrap'}
      gap={2}
    >
      {links?.map(link => (
        <Box key={link.id} flex={1}>
          <Tooltip title={link.title} arrow>
            <ButtonBase
              component={Link}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                padding: 2,
                borderRadius: 1,
                backgroundColor: 'divider',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{color: nsfw ? 'error.light' : 'inherit'}}>
                  {getIconForSocialMedia(link.title)}
                </Box>
                <Stack>
                  <Typography variant="subtitle2">
                    {parseUrl(link.href)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {link.title}
                  </Typography>
                </Stack>
              </Stack>
            </ButtonBase>
          </Tooltip>
        </Box>
      ))}
    </Stack>
  )
}
