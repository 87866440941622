import {Stack, Typography} from '@mui/material'
import {ConnectKitButton} from 'connectkit'

export function ConnectWallet() {
  return (
    <Stack spacing={2} alignItems="center">
      <ConnectKitButton />
      <Typography variant="caption" color="text.secondary">
        Please connect your wallet to donate.
      </Typography>
    </Stack>
  )
}
