import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import {useApplication} from 'hooks'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {AccountStep} from './Account'
import {ApplicantOnly} from './Applied'
import {DetailsStep} from './Details'
import {IDsStep} from './IDs'
import {PaymentsStep} from './Payments'
import {ReviewStep} from './Review'
import {SocialStep} from './Socials'
import {TermsStep} from './Terms'
import {Steps} from './services'

export const steps: Steps[] = [
  'terms',
  'displayName',
  'photoUrl',
  'socials',
  'details',
  'ids',
  'payments',
  'finish',
]

export function ApplicationWizard() {
  const {data: application, isLoading} = useApplication() // Assuming you have a similar hook for applications
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (isLoading) return
    if (application) {
      // setSearchParams({mode: 'apply', step: step ?? application.currentStep})
    } else {
      setSearchParams({mode: 'apply', step: 'terms'})
    }
  }, [application, isLoading, setSearchParams, searchParams])

  const renderStepContent = (step: string) => {
    switch (step) {
      case 'terms':
        return <TermsStep />
      case 'displayName':
        return <AccountStep />
      case 'photoUrl':
        return <AccountStep />
      case 'account':
        return <AccountStep />
      case 'socials':
        return <SocialStep />
      case 'details':
        return <DetailsStep />
      case 'ids':
        return <IDsStep />
      case 'payments':
        return <PaymentsStep />
      case 'finish':
        return <ReviewStep />
      default:
        return <Typography>Invalid step</Typography>
    }
  }

  const currentStep = searchParams.get('step') || 'terms'

  if (application && application.status === 'submitted')
    return <ApplicantOnly />

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  if (!application && !isLoading) {
    return (
      <Box sx={{width: '100%'}}>
        <Typography variant="h6" gutterBottom textAlign={'center'}>
          Application
        </Typography>

        <Box sx={{mt: 2}}>{renderStepContent('terms')}</Box>
      </Box>
    )
  }

  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="h6" gutterBottom textAlign={'center'}>
        Application
      </Typography>

      <Box sx={{mt: 2}}>{renderStepContent(currentStep)}</Box>
    </Box>
  )
}
