import {CircularProgress, Grid2, Stack, Typography} from '@mui/material'
import {PublicProfileCard, SignInWithEmail} from 'components'
import {usePublicProfiles} from 'hooks'
import {useSigninCheck} from 'reactfire'

export function Home() {
  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const {data: profiles, isLoading} = usePublicProfiles()

  return (
    <Stack spacing={16}>
      <Stack spacing={2} textAlign={'center'}>
        <Typography variant="overline" color="text.secondary">
          Pancake Members
        </Typography>
        <Stack>
          {isLoading && !profiles && <CircularProgress />}
          <Grid2 container spacing={5} justifyContent={'center'}>
            {profiles?.map(profile => (
              <Grid2 key={profile.slug} size={12} maxWidth={480}>
                <PublicProfileCard key={profile.slug} profile={profile} />
              </Grid2>
            ))}
          </Grid2>
        </Stack>
      </Stack>

      {!member?.hasRequiredClaims && (
        <Stack spacing={2} textAlign={'center'}>
          <SignInWithEmail />
        </Stack>
      )}
    </Stack>
  )
}
