import {Alert, Button, ButtonProps, Stack} from '@mui/material'
import {PancakeMascot} from 'components'
import {RoundInputSx} from 'components/RoundInputSx'
import {usePhoneVerification, useRecaptcha} from 'hooks'
import {MuiTelInput} from 'mui-tel-input'
import {useCallback, useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useUser} from 'reactfire'

interface PhoneNumberFormValues {
  phoneNumber: string
}

export function PhoneNumber({onSuccess}: {onSuccess: () => void}) {
  const {
    verifyPhoneNumber,
    error,
    setError,
    phoneNumber: storedPhoneNumber,
  } = usePhoneVerification()

  const {control, handleSubmit, watch, formState} =
    useForm<PhoneNumberFormValues>({
      defaultValues: {
        phoneNumber: storedPhoneNumber,
      },
    })

  const {data: user} = useUser()
  const phoneNumber = watch('phoneNumber')
  const {resetRecaptcha} = useRecaptcha()

  const onSubmit = useCallback(
    async (data: PhoneNumberFormValues) => {
      setError('')
      if (user && data.phoneNumber) {
        try {
          await verifyPhoneNumber(
            data.phoneNumber,
            user,
            window.recaptchaVerifier,
          )
          onSuccess()
        } catch (error) {
          console.error('Phone verification error:', error)
          setError((error as Error).message)
          resetRecaptcha()
        }
      } else {
        setError('Please enter a valid phone number.')
      }
    },
    [user, verifyPhoneNumber, setError, onSuccess],
  )

  const buttonProps: ButtonProps = useMemo(
    () => ({
      type: 'submit' as 'submit',
      variant: !phoneNumber ? 'outlined' : 'contained',
      color: 'success' as 'success',
      size: 'large' as 'large',
      disabled: !phoneNumber,
      sx: {height: 54},
      children: !phoneNumber ? `No Number, No Nudes :(` : `Send Nudes`,
    }),
    [phoneNumber],
  )

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <PancakeMascot text="soo… i kinda lied, i need your phone number if you want nudes 😏" />
      <Controller
        name="phoneNumber"
        control={control}
        rules={{required: 'Phone number is required'}}
        render={({
          field,
          fieldState: {error: fieldError, isTouched, isDirty},
        }) => {
          const hasError = !!fieldError && (isTouched || formState.isSubmitted)
          const isSuccess = isDirty && !hasError && formState.isValid

          const getColor = () => {
            if (hasError) return 'error.main'
            if (isSuccess) return 'success.main'
            return 'white'
          }

          return (
            <MuiTelInput
              {...field}
              label="Telephone"
              variant="outlined"
              fullWidth
              error={!!fieldError}
              helperText={fieldError?.message}
              defaultCountry="GB"
              preferredCountries={['US', 'GB']}
              sx={{...RoundInputSx(getColor, getColor, 'left')}}
            />
          )
        }}
      />

      <Button {...buttonProps} />

      {error && <Alert severity="error">{error}</Alert>}
    </Stack>
  )
}
