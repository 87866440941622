import {createTheme} from '@mui/material'

export const theme = createTheme({
  shape: {
    borderRadius: 48,
  },
  typography: {
    // allVariants: {

    //   fontFamily: `Salsa, cursive`,
    // },
    fontFamily: `Salsa, cursive`,
    // fontSize: 13,
    h3: {
      fontSize: '2.5rem',
    },
    subtitle1: {
      fontFamily: 'Roboto',
    },
    subtitle2: {
      fontFamily: 'Roboto',
    },
    body1: {
      fontFamily: 'Roboto',
    },
    body2: {
      fontFamily: 'Roboto',
    },
    caption: {
      fontFamily: 'Roboto',
    },
    overline: {
      fontFamily: 'Roboto',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#EF5B11',
    },
    secondary: {
      main: '#83B462',
    },
    success: {
      main: '#4EC86C',
    },
    background: {
      default: '#1A1A1A',
      paper: '#262828',
    },
  },
})
