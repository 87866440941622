import {Alert, Button, CircularProgress, Stack} from '@mui/material'
import {
  depositCustomTokenState,
  depositMessageState,
  depositOptionsState,
} from 'data'
import {useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {DonateOptionsButton} from './DonateOptionsButton'
import {DonationInputSlider} from './DonateValueSlider'

import {CryptoTxStatus} from 'components/CryptoTxStatus'
import {useActiveProfileBySlug} from 'hooks'
import {useParams} from 'react-router-dom'
import {prepareDonationTransaction} from 'utils'
import {encodeFunctionData, parseUnits} from 'viem'
import {
  useAccount,
  useBalance,
  usePublicClient,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from 'wagmi'
import {DonationMessage} from './DonationMessage'
import {NoMoney} from './NoMoney'

export function DonateForm() {
  const showOptions = useRecoilValue(depositOptionsState)
  const selectedAsset = useRecoilValue(depositCustomTokenState)
  const [amount, setDonationAmount] = useState<number>(1)
  const [message, setMessage] = useRecoilState(depositMessageState)
  const {id} = useParams<{id: string}>()
  const {data: member} = useActiveProfileBySlug(id || '')
  const {address, isConnected} = useAccount()
  const {data: balance} = useBalance({address, token: selectedAsset})
  const [txError, setError] = useState<string | undefined>()

  const {data: hash, isPending, sendTransaction} = useSendTransaction()
  const {isLoading: isConfirming, isSuccess: isConfirmed} =
    useWaitForTransactionReceipt({
      hash,
    })

  const handleDonationChange = (newValue: number) => {
    setDonationAmount(newValue)
  }

  const publicClient = usePublicClient()

  const handleDonate = async () => {
    if (!amount || isNaN(parseFloat(amount.toString()))) {
      console.error('Invalid amount')
      setError(`I couldn't parse this amount, you sure it's a number?`)
      return
    }

    if (!balance || !balance.value) {
      setError(`For some reason your balance isn't loading.`)
      console.error('Balance not available')
      return
    }

    const asset = balance || {
      symbol: 'ETH',
      balance: '0',
      decimals: 18,
      token_address: null,
    }

    const donationAmount = parseUnits(amount.toString(), asset.decimals)
    const balanceAmount = BigInt(balance.value)

    if (donationAmount > balanceAmount) {
      setError(
        `You don't have enough balance to donate ${amount} ${asset.symbol}`,
      )
      console.error('Insufficient balance')
      return
    }

    if (donationAmount <= BigInt(0)) {
      setError(`You're trying to donate less than 0?`)
      console.error('Invalid donation amount')
      return
    }

    const memo = `${member?.docId}::: ${message}`

    try {
      const transaction = prepareDonationTransaction(
        {
          token_address: selectedAsset ? selectedAsset : null,
          symbol: asset.symbol,
          decimals: asset.decimals,
          balance: asset.value.toString(),
        },
        amount.toString(),
        memo,
        import.meta.env.VITE_WALLET_ADDRESS,
      )

      if (!selectedAsset) {
        const gasEstimate = await publicClient.estimateGas({
          to: transaction.to as `0x${string}`,
          data: transaction.data,
          value: transaction.value,
        })

        const totalCost = donationAmount + gasEstimate

        if (totalCost > balanceAmount) {
          setError(
            `You don't have enough balance to donate and cover gas fees. ${amount} ${asset.symbol}`,
          )
          console.error('Insufficient balance including gas')
          return
        }

        // Send the transaction
        sendTransaction(transaction as any)
      } else {
        // Handle ERC20 token transactions here if needed
        const erc20Abi = [
          {
            constant: false,
            inputs: [
              {name: '_to', type: 'address'},
              {name: '_value', type: 'uint256'},
            ],
            name: 'transfer',
            outputs: [{name: '', type: 'bool'}],
            type: 'function',
          },
        ]

        const data = encodeFunctionData({
          abi: erc20Abi,
          functionName: 'transfer',
          args: [import.meta.env.VITE_WALLET_ADDRESS, donationAmount],
        })

        // Estimate gas for the ERC20 token transfer
        const gasEstimate = await publicClient.estimateGas({
          to: selectedAsset,
          data,
          account: address,
        })

        // Check if the user has enough balance to cover the gas cost
        const gasCost = gasEstimate * (await publicClient.getGasPrice())
        const ethBalance = await publicClient.getBalance({
          address: address as `0x${string}`,
        })

        if (ethBalance < gasCost) {
          console.error('Insufficient ETH balance to cover gas costs')
          return
        }

        // Prepare the transaction
        const transaction = {
          to: selectedAsset,
          data,
          gasLimit: gasEstimate,
        }

        // Send the transaction
        sendTransaction(transaction as any)
      }
    } catch (error) {
      console.error('Error preparing or sending transaction:', error)
    }
  }

  return (
    <Stack spacing={2} alignItems="center" width="100%">
      <DonateOptionsButton />
      {showOptions && <NoMoney show={false} />}
      {!showOptions && (
        <Stack width="100%" spacing={2}>
          <DonationInputSlider onChange={handleDonationChange} />
          <DonationMessage
            message={message}
            onChange={setMessage}
            recipientName={id ?? ''}
          />
          <Button
            type="submit"
            variant="contained"
            color="success"
            size="large"
            sx={{py: 2}}
            disabled={isPending || !isConnected || isConfirming}
            onClick={handleDonate}
            startIcon={
              isPending || isConfirming ? <CircularProgress size={16} /> : null
            }
          >
            {isPending ? 'Confirming...' : 'Donate'}
          </Button>
          {txError && <Alert severity="error">{txError}</Alert>}
          {isConfirmed && (
            <CryptoTxStatus txHash={hash} profileName={member?.displayName} />
          )}
        </Stack>
      )}
    </Stack>
  )
}
