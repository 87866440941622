import {EvmChain} from '@moralisweb3/common-evm-utils'
import Moralis from 'moralis'
import {useEffect, useState} from 'react'
import {useAccount, useBalance} from 'wagmi'

export interface Asset {
  token_address: string | null
  symbol: string
  balance: string
  decimals: number
}

export const useUserBalances = () => {
  const [assets, setAssets] = useState<Asset[]>([])
  const [loading, setLoading] = useState(true)
  const {address, chain} = useAccount()
  const {data: nativeBalance} = useBalance({address})

  useEffect(() => {
    const fetchTokenBalances = async () => {
      if (!address || !chain) return

      setLoading(true)

      try {
        const moralisChain = (EvmChain as any)[chain.name]
        const response = await Moralis.EvmApi.token.getWalletTokenBalances({
          address: address as string,
          chain: moralisChain,
        })

        const tokenAssets = response.toJSON().map((token: any) => ({
          token_address: token.token_address,
          symbol: token.symbol,
          balance: token.balance,
          decimals: token.decimals,
        }))

        // Add native token
        const nativeAsset: Asset = {
          token_address: null,
          symbol: chain.nativeCurrency.symbol,
          balance: nativeBalance?.formatted || '0',
          decimals: chain.nativeCurrency.decimals,
        }

        setAssets([nativeAsset, ...tokenAssets])
      } catch (error) {
        console.error('Error fetching token balances:', error)
        setAssets([])
      } finally {
        setLoading(false)
      }
    }

    fetchTokenBalances()
  }, [address, chain, nativeBalance])

  return {assets, loading}
}
