import {
  Explicit,
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  X,
  YouTube,
} from '@mui/icons-material'
import React from 'react'

export const socialIconMap: {[key: string]: React.ReactElement} = {
  Instagram: <Instagram />,
  Facebook: <Facebook />,
  X: <X />,
  TikTok: <Explicit />,
  OnlyFans: <Explicit />,
  YouTube: <YouTube />,
  LinkedIn: <LinkedIn />,
  Twitch: <Explicit />,
  Reddit: <Reddit />,
  Snapchat: <Explicit />,
  Pinterest: <Pinterest />,
  WhatsApp: <WhatsApp />,
  Telegram: <Telegram />,
  Discord: <Explicit />,
  Custom: <Language />,
  'Scrile Connect': <Language />,
  Fansly: <Language />,
  FanCentro: <Language />,
  'MYM.fans': <Language />,
  JustForFans: <Language />,
  Fanvue: <Language />,
  Patreon: <Language />,
  Unlockd: <Language />,
  ManyVids: <Language />,
  LoyalFans: <Language />,
  Unfiltrd: <Language />,
}

export const getIconForSocialMedia = (title: string): React.ReactElement => {
  return socialIconMap[title] || <Language />
}
