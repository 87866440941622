import {Stack, TextField, Typography} from '@mui/material'
import Slider from '@mui/material/Slider'
import {depositCustomTokenState} from 'data'
import {useAssetPrice} from 'hooks/useAssetPrice'
import React, {Fragment, useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import {useAccount, useBalance} from 'wagmi'

interface DonationInputSliderProps {
  onChange: (value: number) => void
}

export const DonationInputSlider: React.FC<DonationInputSliderProps> = ({
  onChange,
}) => {
  const [value, setValue] = useState<number>(1)
  const [maxValue, setMaxValue] = useState<number>(1000)
  const {address} = useAccount()
  const asset = useRecoilValue(depositCustomTokenState)
  const {data: balance} = useBalance({address, token: asset as `0x${string}`})
  const {data: assetPriceInUsd} = useAssetPrice(balance?.symbol || 'ETH')

  useEffect(() => {
    if (balance) {
      const maxBalance = parseFloat(balance.formatted)
      setMaxValue(maxBalance)
      if (maxBalance > 0) {
        setValue(maxBalance / 2)
        onChange(maxBalance / 2)
      }
    }
  }, [balance])

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    const donationValue = newValue as number
    setValue(donationValue)
    onChange(donationValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const donationValue =
      event.target.value === '' ? 0 : Number(event.target.value)
    setValue(donationValue)
    onChange(donationValue)
  }

  const handleBlur = () => {
    if (value < 0.01) {
      setValue(0.01)
      onChange(0.01)
    } else if (value > maxValue) {
      setValue(maxValue)
      onChange(maxValue)
    } else {
      setValue(value)
      onChange(value)
    }
  }

  const usdAmount = (
    parseFloat(value.toString()) * (assetPriceInUsd || 0)
  ).toFixed(2)

  return (
    <Fragment>
      <Stack direction="column" alignItems="center" justifyContent={'center'}>
        <TextField
          value={value}
          size="medium"
          onChange={handleInputChange}
          onBlur={handleBlur}
          fullWidth
          label={`Amount in ${balance?.symbol}`}
          slotProps={{
            input: {
              inputProps: {
                step: 0.01,
                min: 0.01,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              },
              endAdornment: (
                <Typography variant="caption" color="text.disabled">
                  ${usdAmount}
                </Typography>
              ),
            },
          }}
        />
      </Stack>
      <Slider
        value={typeof value === 'number' ? value : 0}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        min={0.01}
        max={maxValue}
        step={0.01}
        sx={{width: '100%'}}
        color="success"
      />
    </Fragment>
  )
}
