import {Alert, Button, Link} from '@mui/material'
import {useAccount, useWaitForTransactionReceipt} from 'wagmi'

interface TransactionStatusProps {
  txHash?: `0x${string}`
  profileName?: string
}

export function CryptoTxStatus({txHash, profileName}: TransactionStatusProps) {
  const {chain} = useAccount()
  const {isSuccess: isTransactionSuccess} = useWaitForTransactionReceipt({
    hash: txHash,
  })

  const getExplorerLink = (hash?: `0x${string}`) => {
    if (chain?.blockExplorers?.default) {
      return `${chain.blockExplorers.default.url}/tx/${hash}`
    }
    return `https://etherscan.io/tx/${hash}`
  }

  if (!isTransactionSuccess || !txHash) return null

  return (
    <Alert
      severity="info"
      variant="outlined"
      action={
        <Button
          component={Link}
          href={getExplorerLink(txHash)}
          target="_blank"
          color="info"
        >
          Receipt
        </Button>
      }
    >
      Donated to {profileName}
    </Alert>
  )
}
