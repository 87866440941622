import {Box, CircularProgress, Stack} from '@mui/material'
import {depositCustomTokenState} from 'data'
import {useActiveProfileBySlug} from 'hooks'
import {useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {useAccount, useBalance} from 'wagmi'
import {ConnectWallet} from './ConnectWallet'
import {DonateForm} from './DonateForm'
import {NoMoney} from './NoMoney'
import {NoProfileRedirect} from './NoProfileRedirect'

const LoadingUi = () => (
  <Stack alignItems={'center'}>
    <CircularProgress />
  </Stack>
)

export function DonationWizard() {
  const {id: memberSlug} = useParams<{id: string}>()
  const {data: member, isLoading} = useActiveProfileBySlug(memberSlug || '')
  const {isConnected, address} = useAccount()
  const token = useRecoilValue(depositCustomTokenState)
  const {data: balance} = useBalance({address, token})

  // Placeholder for donation amount limits
  // const minDonation = '0.01'
  // const maxDonation = '10'

  const hasBalance = balance && balance.value > BigInt(0)

  if (!memberSlug) return <NoProfileRedirect />
  if (isLoading) return <LoadingUi />
  if (!member) return <NoProfileRedirect />

  return (
    <Stack width="100%">
      <Box position="relative">
        {!isConnected && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(26, 26, 26, 0.64)',
              backdropFilter: 'blur(4px)',
              zIndex: 4,
              scale: 1.05,
              p: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ConnectWallet />
          </Box>
        )}
        {isConnected && !hasBalance && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(26, 26, 26, 0.64)',
              backdropFilter: 'blur(4px)',
              zIndex: 4,
              scale: 1.05,
              p: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NoMoney />
          </Box>
        )}
        <DonateForm />
      </Box>
    </Stack>
  )
}
