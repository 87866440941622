import {useQuery} from '@tanstack/react-query'
import Moralis from 'moralis'
import {
  EvmChain,
  GetWalletTransactionsJSONResponse,
} from 'moralis/common-evm-utils'
import {decodeAbiParameters} from 'viem'

interface UseWalletTransactionsParams {
  address: string | `0x${string}`
  chain: EvmChain | undefined
  uid?: string // Made optional
}

interface decodedTransactionInputResponse {
  message: string
  uid: string
}

export function decodeTransactionInput(
  input: string,
): decodedTransactionInputResponse | undefined {
  try {
    // Remove the function selector (first 4 bytes / 8 characters)
    const data: `0x${string}` = `0x${input.slice(10)}`

    // Decode the data
    const decodedData = decodeAbiParameters([{type: 'string'}], data)

    // The decoded data is an array, so we take the first element
    return {
      message: decodedData[0].split(':::')[1],
      uid: decodedData[0].split(':::')[0],
    }
  } catch (error) {
    console.error('Error decoding transaction input:', error)
    return undefined
  }
}

type WalletTxResult = GetWalletTransactionsJSONResponse['result'][number]

export interface ExtendedWalletTx extends WalletTxResult {
  message?: string
  uid?: string
}

export const useWalletTransactions = ({
  address,
  chain,
  uid,
}: UseWalletTransactionsParams) => {
  const fetchTransactions = async (): Promise<ExtendedWalletTx[]> => {
    try {
      const response = await Moralis.EvmApi.transaction.getWalletTransactions({
        address,
        chain,
      })
      const results = response.toJSON().result

      const extendedResults: ExtendedWalletTx[] = results.map(tx => {
        const decodedInput = decodeTransactionInput(tx.input)
        return {
          ...tx,
          message: decodedInput?.message,
          uid: decodedInput?.uid,
        }
      })

      // Filter the results by the parsed userId (uid)
      const filteredResults = uid
        ? extendedResults.filter(tx => tx.uid === uid)
        : extendedResults

      return filteredResults
    } catch (error) {
      console.error('Error fetching transactions:', error)
      throw error
    }
  }

  return useQuery<ExtendedWalletTx[], Error>({
    queryKey: ['walletTransactions', address, chain, uid],
    queryFn: fetchTransactions,
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: !!uid,
  })
}
