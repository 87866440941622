import {Alert} from '@mui/material'
import {useEffect} from 'react'
import {useUser} from 'reactfire'

export function ApplicantOnly() {
  const {data: user} = useUser()
  useEffect(() => {
    if (!user) return
    ;(async () => {
      await user.getIdToken(true)
    })()
  }, [user])
  return (
    <Alert severity="info" sx={{mb: 2}}>
      Thank you for your application, a Pancake team member will review and get
      back to you soon. Please look out for emails from Pancake for greatness to
      come.
    </Alert>
  )
}
