import {Close} from '@mui/icons-material'
import {Alert, AlertTitle, IconButton, Stack, TextField} from '@mui/material'
import {depositCustomTokenState} from 'data'
import React, {useEffect, useState} from 'react'
import {useRecoilState} from 'recoil'
import {formatUnits, isAddress} from 'viem'
import {useAccount, useBalance} from 'wagmi'

export function TokenAddressLookup() {
  const [isValidAddress, setIsValidAddress] = useState(true)
  const [selectedAsset, setSelectedAsset] = useRecoilState(
    depositCustomTokenState,
  )
  const [tokenAddress, setTokenAddress] = useState(
    selectedAsset ? (selectedAsset as string) : '',
  )

  const {address: userAddress} = useAccount()

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value
    setTokenAddress(address as `0x${string}`)
    setIsValidAddress(address === '' || isAddress(address))
  }

  const {data: balance, refetch: refetchBalance} = useBalance({
    address: userAddress,
    token: isAddress(tokenAddress as string)
      ? (tokenAddress as `0x${string}`)
      : undefined,
  })

  useEffect(() => {
    if (!isAddress(tokenAddress as string)) return // Skip if not a valid address
    console.log('refecthing')
    refetchBalance()
  }, [tokenAddress])

  useEffect(() => {
    if (!balance) return // Skip if no balance
    if (!isAddress(tokenAddress as string)) return // Skip if not a valid address
    if (balance.value > 0) {
      setSelectedAsset(tokenAddress as `0x${string}`)
    }
  }, [balance, tokenAddress])

  const handleClear = () => {
    setTokenAddress('')
    setSelectedAsset(undefined)
  }

  return (
    <Stack spacing={2} width="100%">
      <TextField
        fullWidth
        label="Custom Token Address"
        value={tokenAddress}
        onChange={handleAddressChange}
        error={!isValidAddress}
        helperText={!isValidAddress ? 'Invalid Ethereum address' : ''}
        slotProps={{
          input: {
            endAdornment: (
              <IconButton onClick={handleClear}>
                <Close />
              </IconButton>
            ),
          },
        }}
      />

      {balance && balance?.value && balance?.value > 0 ? (
        <Alert severity={'success'} variant="outlined" sx={{borderRadius: 0.5}}>
          <AlertTitle>
            {balance.symbol} ({balance.decimals} decimals)
          </AlertTitle>
          Balance:
          {formatUnits(balance.value, balance.decimals)} {balance.symbol}
        </Alert>
      ) : (
        <Alert severity="error">You don't have any tokens to donate.</Alert>
      )}
    </Stack>
  )
}
