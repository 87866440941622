import {
  Avatar,
  Box,
  CircularProgress,
  Fade,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import {SignInWithEmail} from 'components'
import {benefits as _benefits, members} from 'data'
import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'
import {ROUTES} from 'router'

// Extend benefits to include member details
export const benefits = _benefits.map((benefit, index) => {
  const member = members[index % members.length] // Use modulo to repeat members if needed
  return {
    ...benefit,
    memberName: member.name,
    memberProfileLink: member.profileLink,
    memberImagePath: member.imagePath,
  }
})

const wh = 220

export function Join() {
  const [currentIndex, setCurrentIndex] = useState(0) // State to keep track of the current slide index
  const [inProp, setInProp] = useState(true) // State to manage fade effect
  const [searchParams] = useSearchParams()
  const [isSignInMode, setIsSignInMode] = useState(false)

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const navigate = useNavigate()

  useEffect(() => {
    const mode = searchParams.get('mode')
    setIsSignInMode(mode === 'signIn')
  }, [searchParams])

  useEffect(() => {
    if (member.hasRequiredClaims) navigate(ROUTES.PROFILES)
  }, [member, navigate])

  // Automatic interval to cycle through slides every 7.5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext() // Use the handleNext function to update the slide index
    }, 7500)

    return () => clearInterval(intervalId) // Cleanup interval on component unmount
  }, [])

  // Function to handle going to the next slide
  const handleNext = () => {
    setInProp(false) // Trigger fade-out
    setTimeout(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % benefits.length) // Update the current index
      setInProp(true) // Trigger fade-in
    }, 300) // Short delay for fade-out before changing content
  }

  if (isSignInMode) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Stack spacing={2} alignItems="center" textAlign={'center'}>
      {/* Sign-in component */}
      <Box maxWidth={480} width="100%" pb={2}>
        <SignInWithEmail />
      </Box>

      {/* Slider for Member Details */}
      <Typography variant="overline" color="textSecondary">
        profiles
      </Typography>
      <Fade in={inProp} timeout={500}>
        <Box>
          <Stack spacing={0} alignItems={'center'} position="relative">
            <Avatar
              src={benefits[currentIndex].memberImagePath}
              alt={benefits[currentIndex].memberName}
              variant="rounded"
              sx={{width: wh, height: wh, position: 'relative'}}
            />
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                bottom: 8,
                zIndex: 1,
                background: `#1A1A1A`,
                px: 1,
                borderRadius: 24,
                margin: '0 auto',
                fontSize: '1rem',
              }}
            >
              {benefits[currentIndex].memberName}
            </Typography>
          </Stack>
          <Typography variant="caption" mt={2}>
            <Link
              href={benefits[currentIndex].memberProfileLink}
              underline="hover"
            >
              pancake.bio{benefits[currentIndex].memberProfileLink}
            </Link>
          </Typography>
        </Box>
      </Fade>
    </Stack>
  )
}
