import {TextField, TextFieldProps} from '@mui/material'
import React from 'react'

interface DonateMessageProps {
  message: string
  onChange: (message: string) => void
  recipientName: string
  textFieldProps?: Partial<TextFieldProps>
}

export const DonationMessage: React.FC<DonateMessageProps> = ({
  message,
  onChange,
  recipientName,
  textFieldProps = {},
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  return (
    <TextField
      multiline
      rows={1}
      fullWidth
      value={message}
      label={`Message to ${recipientName}`}
      onChange={handleChange}
      sx={{borderRadius: 0}}
      slotProps={{
        input: {sx: {borderRadius: `24px !important`}},
      }}
      placeholder={`Send a message to ${recipientName} along with your donation. (optional)`}
      {...textFieldProps}
    />
  )
}
