import {CloseRounded, PhotoAlbumRounded} from '@mui/icons-material'
import {Alert, Avatar, Box, Button, Stack, TextField} from '@mui/material'
import {PancakeMascot} from 'components'
import {profileStepState} from 'data'
import {updateProfile} from 'firebase/auth'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import React, {useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useAuth, useUser} from 'reactfire'
import {useSetRecoilState} from 'recoil'

type FormValues = {
  displayName: string
  photoURL?: FileList | null
}

export function AccountStep() {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: {errors, isValid},
    register,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {displayName: '', photoURL: null},
  })

  const [, setSearchParams] = useSearchParams()
  const setActiveStep = useSetRecoilState(profileStepState)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [uploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const auth = useAuth()
  const {data: user} = useUser()
  const storage = getStorage()

  const displayName = watch('displayName')
  const photoURL = watch('photoURL')

  useEffect(() => {
    // Set initial values when the component mounts
    if (!user) return
    reset({displayName: user.displayName || '', photoURL: null})
    if (user.photoURL) setImagePreview(user.photoURL)
  }, [user, setValue])

  useEffect(() => {
    if (photoURL && photoURL.length > 0) {
      const file = photoURL[0]
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        setImagePreview(fileReader.result as string)
      }
      fileReader.readAsDataURL(file)
    }
  }, [photoURL])

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setValue('photoURL', files)
      trigger('photoURL')
    } else {
      setValue('photoURL', null)
    }
  }

  const handleClearImage = () => {
    setValue('photoURL', null)
    setImagePreview(user?.photoURL || null)
  }

  const onSubmit = async (data: FormValues) => {
    if (!auth.currentUser) return

    setUploading(true)

    try {
      let photoDownloadURL = user?.photoURL

      if (data.photoURL && data.photoURL.length > 0) {
        const file = data.photoURL[0]
        const storageRef = ref(storage, `dp/${auth.currentUser.uid}`)
        await uploadBytes(storageRef, file)
        photoDownloadURL = await getDownloadURL(storageRef)
      }

      if (!photoDownloadURL) return setError('Photo upload failed.')

      await updateProfile(auth.currentUser, {
        displayName: data.displayName,
        photoURL: photoDownloadURL,
      })

      console.log('Profile updated successfully')
      setActiveStep(prevStep => prevStep + 1)
      console.log('setting next step')
      setSearchParams({mode: 'apply', step: 'socials'})
    } catch (error) {
      console.error('Error updating profile:', error)
    } finally {
      setUploading(false)
    }
  }

  const handleBack = () => {
    setSearchParams({mode: 'apply', step: 'account'})
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <PancakeMascot
          text={
            !imagePreview
              ? "Let's set up your profile! Choose a display name and upload a photo."
              : `Looking great! Click 'Next' when you're ready to continue.`
          }
        />

        <Stack alignItems="center" spacing={2}>
          <Avatar sx={{height: 180, width: 180}} src={imagePreview || ''} />

          <input
            type="file"
            accept="image/*"
            {...register('photoURL', {
              validate: {
                fileSize: value =>
                  !value ||
                  value.length === 0 ||
                  value[0].size <= 10000000 ||
                  'File size should not exceed 10MB.',
                fileType: value =>
                  !value ||
                  value.length === 0 ||
                  ['image/jpeg', 'image/png', 'image/gif'].includes(
                    value[0].type,
                  ) ||
                  'Invalid file type. Only JPG, PNG, and GIF are allowed.',
              },
            })}
            ref={fileInputRef}
            style={{display: 'none'}}
            onChange={handleFileChange}
          />

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFileInputClick}
              startIcon={<PhotoAlbumRounded />}
            >
              {imagePreview ? 'Change Image' : 'Select Image'}
            </Button>
            {imagePreview && (
              <Button
                variant="outlined"
                color="warning"
                onClick={handleClearImage}
                startIcon={<CloseRounded />}
              >
                Clear Image
              </Button>
            )}
          </Stack>

          {errors.photoURL && (
            <Alert severity="error">{errors.photoURL.message}</Alert>
          )}

          <Alert severity="info">
            Please select a square image under 2MB for best results.
          </Alert>
        </Stack>
        <Controller
          name="displayName"
          control={control}
          rules={{required: 'Display name is required'}}
          render={({field}) => (
            <TextField
              {...field}
              label="Display Name"
              variant="outlined"
              fullWidth
              error={Boolean(errors.displayName)}
              helperText={errors.displayName?.message}
            />
          )}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>
      <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
        <Button
          color="inherit"
          disabled={true}
          onClick={handleBack}
          sx={{mr: 1}}
        >
          Back
        </Button>
        <Box sx={{flex: '1 1 auto'}} />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            !isValid || uploading || !displayName || displayName.trim() === ''
          }
        >
          {uploading ? 'Updating...' : 'Next'}
        </Button>
      </Box>
    </form>
  )
}
