import {VisibilityOffRounded} from '@mui/icons-material'
import {Box, ButtonBase, Paper, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {useUser} from 'reactfire'
import {ProfileData} from 'types'

export function PublicProfileCard({profile}: {profile: ProfileData}) {
  const {displayName, pfp, slug, userId} = profile
  const {data: user} = useUser()
  const isOwner = user && user.uid === userId
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 'rgba(239,91,17,0.24) 0 0 16px 0px',
          zIndex: 1,
        },
        transition: 'all 0.3s ease-in-out',
        height: '100%',
        position: 'relative',
        paddingTop: '100%', // This creates a 1:1 aspect ratio
      }}
    >
      <ButtonBase
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }}
        onClick={() => navigate(`/m/${slug}`)}
      >
        <Box
          sx={{
            backgroundImage: `url(${pfp})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 1,
            p: 2,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {isOwner && !profile.active && (
            <Box
              bgcolor={'rgba(0,0,0,0.48)'}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <VisibilityOffRounded color="warning" />
            </Box>
          )}
          <Typography
            sx={{
              background: 'rgba(0,0,0,0.82)',
              p: 0.5,
              px: 2,
              borderRadius: 1,
              position: 'absolute',
              bottom: 8,
              textAlign: 'center',
              width: 'auto',
            }}
            fontWeight={'medium'}
          >
            {displayName}
          </Typography>
        </Box>
      </ButtonBase>
    </Paper>
  )
}
