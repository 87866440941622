import {Avatar, Button, Stack} from '@mui/material'
import {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSigninCheck, useUser} from 'reactfire'
import {ROUTES} from 'router'
import {DrawerMenu} from './DrawerMenu'

export function Navigation() {
  const {data: applicant} = useSigninCheck({
    requiredClaims: {applicant: true},
  })

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const isSignedIn = member?.signedIn || applicant?.signedIn
  const isApplied =
    isSignedIn && applicant?.hasRequiredClaims && !member?.hasRequiredClaims
  const isMember = isSignedIn && member?.hasRequiredClaims
  const isApplicant = isSignedIn && !isApplied && !isMember

  console.log('isSignedIn', isSignedIn)
  console.log('isApplied', isApplied)
  console.log('isMember', isMember)
  console.log('isApplicant', isApplicant)

  const navigate = useNavigate()
  const {data: user} = useUser()

  const handleButton = useCallback(() => {
    if (isSignedIn) {
      return navigate(ROUTES.JOIN)
    } else if (isApplicant) {
      return navigate(ROUTES.JOIN)
    } else if (isApplied) {
      return navigate(ROUTES.DASHBOARD)
    } else if (isMember) {
      return navigate(ROUTES.DASHBOARD)
    } else {
      return navigate(ROUTES.JOIN)
    }
  }, [applicant, member])

  return (
    <Stack direction="row" spacing={0.5} alignItems={'center'}>
      <Button
        variant="contained"
        color="inherit"
        onClick={handleButton}
        sx={{position: 'relative', pl: user?.photoURL ? 5 : 2}}
        startIcon={
          user?.photoURL ? (
            <Avatar
              sx={{
                width: 'auto',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              variant="circular"
              src={user?.photoURL ?? undefined}
            />
          ) : null
        }
      >
        {!isSignedIn && `Join`}
        {isApplicant && `Earn Money`}
        {isApplied && `Profile`}
        {isMember && `Dashboard`}
      </Button>

      <DrawerMenu />
    </Stack>
  )
}
