import {AuthLayout, WrapperLayout} from 'layouts'
import {
  Account,
  Contact,
  CookiePolicy,
  Dashboard,
  Home,
  Join,
  Member,
  MemberDonate,
  Members,
  Membership,
  PrivacyPolicy,
  Profiles,
  TermsAndConditions,
} from 'pages'
import {Route, Routes} from 'react-router-dom'

import {ProfileCreation, ProfileWizard} from 'forms'
import {ROUTES} from './routes'

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<WrapperLayout />}>
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.COOKIE} element={<CookiePolicy />} />
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.MEMBER} element={<Member />} />
        <Route path={ROUTES.MEMBER_DONATE} element={<MemberDonate />} />
        <Route path={ROUTES.MEMBERS} element={<Members />} />
        <Route path={ROUTES.MEMBERSHIP} element={<Membership />} />
        <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.LOGIN} element={<Join />} />
        <Route path={ROUTES.TERMS} element={<TermsAndConditions />} />
        {/* Protected Routes */}
        <Route path={ROUTES.HOME} element={<AuthLayout />}>
          <Route path={ROUTES.JOIN} element={<Join />} />
          <Route path={ROUTES.ACCOUNT} element={<Account />} />
        </Route>
        <Route path={ROUTES.HOME} element={<AuthLayout />}>
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.PROFILES} element={<Profiles />} />
          <Route path={ROUTES.PROFILE_CREATE} element={<ProfileCreation />} />
          <Route path={ROUTES.PROFILE_EDIT} element={<ProfileWizard />} />
        </Route>
      </Route>
    </Routes>
  )
}
