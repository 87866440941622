import {Stack, Typography} from '@mui/material'
import {CoinPicker} from './CoinPicker'
import {NetworkSwitch} from './NetworkSwitch'
import {TokenAddressLookup} from './TokenAddressLookup'

export function NoMoney({show = true}: {show?: boolean}) {
  return (
    <Stack spacing={2} alignItems="center" width="100%">
      {show && (
        <Typography variant="caption" color="text.secondary">
          You don't have any funds to donate. Switch blockchain, account or add
          a custom token.
        </Typography>
      )}
      <NetworkSwitch />
      <CoinPicker />
      <TokenAddressLookup />
    </Stack>
  )
}
