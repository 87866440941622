import {Box, Fade} from '@mui/material'
import {isOtcState} from 'data'
import {useEffect, useRef, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {OneTimeCode} from './OneTimeCode'
import {PhoneNumber} from './PhoneNumber'

export function RegistrationWizard() {
  const [isOtc, setIsOtc] = useRecoilState(isOtcState)
  const [searchParams, setSearchParams] = useSearchParams()
  const [outrow, setOutrow] = useState<boolean>(true)
  const [contentHeight, setContentHeight] = useState(0)
  const phoneNumberRef = useRef<HTMLDivElement>(null)
  const otcRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mode = searchParams.get('mode')
    const step = searchParams.get('step')

    if (mode !== 'register') {
      setSearchParams({mode: 'register', step: 'phoneNumber'})
    }

    if (mode === 'register') {
      if (step === 'phoneNumber') {
        setIsOtc(false)
      } else if (step === 'oneTimeCode') {
        setIsOtc(true)
      }
    }
  }, [searchParams, setIsOtc, setSearchParams])

  useEffect(() => {
    const updateHeight = () => {
      const currentRef = isOtc ? otcRef.current : phoneNumberRef.current
      if (currentRef) {
        setContentHeight(currentRef.offsetHeight)
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [isOtc])

  const handlePhoneNumberSuccess = () => {
    setIsOtc(true)
    setSearchParams({mode: 'register', step: 'oneTimeCode'})
  }

  const handleOtcSuccess = () => {
    setOutrow(true)
    setSearchParams({mode: 'apply', step: 'terms'})
    // setIsOtc(false)
  }

  const handleOtcBack = () => {
    setIsOtc(false)
    setSearchParams({mode: 'register', step: 'phoneNumber'})
  }

  return (
    <Fade in={!!outrow} timeout={500}>
      <Box sx={{width: '100%'}}>
        <Box
          sx={{
            mt: 2,
            position: 'relative',
            height: contentHeight,
            transition: 'height 0.5s',
          }}
        >
          {isOtc ? (
            <Fade in={true} timeout={500}>
              <Box sx={{width: '100%'}}>
                <div ref={otcRef}>
                  <OneTimeCode
                    onSuccess={handleOtcSuccess}
                    onBack={handleOtcBack}
                  />
                </div>
              </Box>
            </Fade>
          ) : (
            <Fade in={true} timeout={500}>
              <Box sx={{width: '100%'}}>
                <div ref={phoneNumberRef}>
                  <PhoneNumber onSuccess={handlePhoneNumberSuccess} />
                </div>
              </Box>
            </Fade>
          )}
        </Box>
        <div id="recaptcha-container" />
      </Box>
    </Fade>
  )
}
